import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, FormControl, Select, MenuItem, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, readableBytes } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { getUserThreatTableData } from "../../../actions/Users/authenticateThreatDashboard";
// import { getUptimeTableData } from "../../actions/Users/authenticateTrends";
import _ from "lodash";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import "./../ThreatDashboard.css";
import MultiColorBar from "./MultiColorBar";


const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[0], row[2], row[3], row[4], row[5], row[6], row[7], row[8]])
    })
    return [['User Name', 'IP ADDRESS', 'SITE NAME', 'COUNT', 'CRITICAL', 'HIGH', 'MEDIUM', 'LOW'], ...csvRows]
}

const UserThreatTable = (props) => {
    const { timePeriod, getUserThreatTableData, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType, setDashboardUptimeSiteStatisticsTableLoading, location, history, authReducer, downloadAsCSV, userThreatTableData } = props;

    const _q = getDecodeURI(location?.search);
    const DEVICE_NAME = 'Device_Name';
    const USER_NAME = 'hostname';
    const SITE_NAME = 'sitename';
    const TOTAL_HITS = 'total_hits'

    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const DEFAULT_PAGE_SIZE = 10;
    const ENTER_KEY_CODE = 'Enter';
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sortUserThreat") ? _q.sortUserThreat : "total_hits");
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const [vessels, setVessels] = useState<any[]>([]);

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if (vessels?.length > 0) {
            const window_value = (timePeriod?.minutes / 5);
            getUserThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, 0, limit, sort, sortOrder, searchValue, window_value);
            setPage(0);
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType])

    useEffect(() => {
        if (!_.isEmpty(userThreatTableData)) {
            const data = userThreatTableData?.hasOwnProperty('data') ? userThreatTableData?.data : {};
            const dataArr: any = [];
            setCount(data?.rows?.[0]?.[10]);
            data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [userThreatTableData])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'all-therat-table') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows])
                    , {
                        formatters: {
                            0: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sortUserThreat = field;
        params.sortOrder = order;
        const Page = 0;
        setPage(0);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUserThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, field, order, searchValue, window_value)
    }

    const setSorting = (event: any, field: any, order: any) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value - 1);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUserThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, value - 1, limit, sort, sortOrder, searchValue, window_value);
    }

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 0;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(0);
        setLimit(Limit);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUserThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, Limit, sort, sortOrder, searchValue, window_value);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 0;
        setPage(0);
        doNavigate(params);
        const window_value = (timePeriod?.minutes / 5);
        vessels && getUserThreatTableData(vessels, newSummaryStartDate, newSummaryEndDate, newSummarySeverityType?.value, Page, limit, sort, sortOrder, value, window_value);
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleDownloadReport = () => {
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);

        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: "all-therat-table",
            queryName: 'SNORT_DASHBOARD_USER_VERNABILITY_TABLE',
            payload: {
                siteid: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                start_time: startDate,
                end_time: endDate,
                name: "",
                devicename: "",
                pageOffset: page ? page : 0,
                pageSize: limit,
                search: searchValue,
                sort_column: sort ? sort : "priority asc,Attack_Time",
                sort_order: sortOrder ? sortOrder : "desc",
                severity: newSummarySeverityType.value && newSummarySeverityType.value !== "all" ? `and severity='${newSummarySeverityType.value.toUpperCase()}'` : "",

            }

        }
        downloadAsCSV(params);
    }

    const handleSeverityTypeClick = (severity) => {
        let params: any = getDecodeURI(location?.search);
        params.severityType = severity;
        doNavigate(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const getColorBarData = (item) => {
        let colorBardata = [
            { count: item?.cirtical_count, color: '#D62728' },    // Critical
            { count: item?.high_count, color: '#FF7F0E' },    // High
            { count: item?.medium_count, color: '#FFBF00' },   // Medium
            { count: item?.low_count, color: '#1F77B4' },    // Low
        ];
        return colorBardata = colorBardata.filter(item => item.count !== 0);;
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">{`Endpoint Vulnerability`}</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid item xs={4} sm={4} md={4} lg={4} className="margin-left-auto">
                            <Box>
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    classes={{ root: "input-box-uptime-tbl" }}
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "serachBar",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="cursorPointer"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid className="downloadBtnContainer usage-downloadBtnContainer">
                            <div className="download-summary-btn marg-top-2" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page + 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USER_NAME)}>USER NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === USER_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USER_NAME, ASC_ORDER)} />
                                                <img src={sort === USER_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USER_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900">IP ADDRESS</span>
                                            {/* <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, ASC_ORDER)} />
                                                <img src={sort === DEVICE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>
                                    {/* <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, THREAT_NAME)}>NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === THREAT_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THREAT_NAME, ASC_ORDER)} />
                                                <img src={sort === THREAT_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, THREAT_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell> */}
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOTAL_HITS)}>COUNT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOTAL_HITS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL_HITS, ASC_ORDER)} />
                                                <img src={sort === TOTAL_HITS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTAL_HITS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900">VULNERABILITIES</span>
                                            {/* <Grid className="sort-icon-margin">
                                            onClick={(event) => switchSorting(event, LTE)}
                                                <img src={sort === LTE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LTE, ASC_ORDER)} />
                                            <img src={sort === LTE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, LTE, DESC_ORDER)} />
                                            </Grid> */}
                                        </span>
                                    </TableCell>



                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length && !setDashboardUptimeSiteStatisticsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">

                                    {
                                        tableData && tableData.map((item, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.hostname ? item?.hostname : 'no data'}</span>}>
                                                    <TableCell className="login-left-align inter-font  Table-Cell-Opacity elipseThreat">{item?.hostname}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.ip_address ? item?.ip_address : 'no data'}</span>}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.ip_address ? item?.ip_address : '-'}</TableCell>
                                                </Tooltip>
                                                {/* <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.Threat_Name ? item?.Threat_Name : 'no data'}</span>}>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.Threat_Name ?  item?.Threat_Name : '-'}</TableCell>
                                            </Tooltip> */}
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{item?.sitename ? item?.sitename : 'no data'}</span>}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.sitename ? item?.sitename : '-'}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity ">{item?.total_hits ? item?.total_hits : '-'}</TableCell>
                                                {/* <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.cirtical_count ?  item?.cirtical_count : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.high_count ?  item?.high_count : '-'}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.medium_count ?  item?.medium_count : '-'}</TableCell> */}
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    {/* {item?.low_count ?  item?.low_count : '-'} */}
                                                    <MultiColorBar data={getColorBarData(item)} />
                                                </TableCell>
                                                {/* <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.Protocol ?  item?.Protocol : '-'}</TableCell> */}


                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryUptimeTable: state.authReducer?.newSummaryUptimeTable,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummarySeverityType: state?.authReducer?.newSummarySeverityType,
    userThreatTableData: state?.authReducer?.userThreatTableData
});

export default withRouter(
    connect(mapStateToProps, { getUserThreatTableData, downloadAsCSV })(UserThreatTable)
)