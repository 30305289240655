import { nanoid } from "nanoid";
import { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";


import { Button, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Checkbox, FormHelperText, Tooltip } from "@mui/material";
import { RemoveCircle } from '@mui/icons-material';


import { Action, Actions, ActionType } from "../types";
import { AddCircle } from "@material-ui/icons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ from "lodash";
import { checkLoggedInUserAuthorizedToViewPage } from "../../../utils/util";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import "./QuotaActions.css";

interface QuotaActionsProps {
    value: Action[];
    readOnly?: boolean;
    forRevert?: boolean;
    onChange?: (actions: Action[]) => void;
}

const STARLINK_ADMIN_SF = "starlink-admin";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];

function QuotaActions(props: QuotaActionsProps) {

    const { value, readOnly, forRevert, onChange } = props;

    const [quotaActions, setQuotaActions] = useState<Action[]>(value)

    const isAdmin = useMemo(() => {
        return checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)
    }, [])

    const addQuotaAction = () => {
        quotaActions.push({
            id: nanoid(),
            usage_percentage: 10,
            type: Actions.ActionUndefined,
            executed: false,
            revert: forRevert
        })
        let _quotaActions = [...quotaActions]
        if (onChange) onChange(_quotaActions)
    }

    const deleteQuotaAction = (id: string) => () => {
        quotaActions.splice(quotaActions.findIndex(condition => condition.id === id), 1)
        let _quotaActions = [...quotaActions]
        if (onChange) onChange(_quotaActions)
    }

    const changeQuotaAction = (id: string) => (condition: Action) => {
        let _quotaActions = [...quotaActions]
        _quotaActions[_quotaActions.findIndex(condition => condition.id === id)] = condition
        if (onChange) onChange(_quotaActions)
    }

    useEffect(() => {
        setQuotaActions(value)
    }, [value])

    return (
        <Fragment>
            {quotaActions.map(condition => <QuotaAction isAdmin={isAdmin} condition={condition} key={condition.id}
                onChange={changeQuotaAction(condition.id)} removeComp={
                    <IconButton aria-label="remove condition" onClick={deleteQuotaAction(condition.id)} disabled={readOnly || (!isAdmin && (condition.type === Actions.ActionPauseServiceLine || condition.type === Actions.ActionResumeServiceLine))}>
                        <RemoveCircle />
                    </IconButton>
                } readOnly={readOnly || (!isAdmin && (condition.type === Actions.ActionPauseServiceLine || condition.type === Actions.ActionResumeServiceLine))} />)}
            {
                !readOnly && <Button variant="outlined" size="small" startIcon={<AddCircle />} className="quota_management--button_outlined--primary" onClick={addQuotaAction} >
                    ADD ACTION
                </Button>
            }
        </Fragment>
    )
}

interface QuotaActionProps {
    condition: Action;
    removeComp: JSX.Element;
    readOnly?: boolean;
    isAdmin?: boolean;
    onChange?: (action: Action) => void;
}

function QuotaAction(props: QuotaActionProps) {

    const { condition, removeComp, readOnly, isAdmin, onChange } = props;

    const [exhaustPercent, setExhaustPercent] = useState<number>(condition.usage_percentage)
    const [actionOnExhaust, setActionOnExhaust] = useState<ActionType>(condition.type)

    const handleChangeExhaustPercent = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.valueAsNumber < 1) {
            setExhaustPercent(1)
        } else {
            setExhaustPercent(event.target.valueAsNumber)
        }
    }

    const handleChangeActionOnExhaust = (event: SelectChangeEvent) => {
        const _action = event.target.value as ActionType
        setActionOnExhaust(_action)
    }

    useEffect(() => {
        !readOnly && onChange && onChange({
            ...condition,
            usage_percentage: exhaustPercent,
            type: actionOnExhaust,
            executed: condition.executed || false,
            revert: condition.revert || false,
            valid:
                actionOnExhaust !== Actions.ActionUndefined &&
                (
                    actionOnExhaust === Actions.ActionNotification ||
                    actionOnExhaust === Actions.ActionPauseServiceLine ||
                    actionOnExhaust === Actions.ActionResumeServiceLine ||
                    actionOnExhaust === Actions.ActionOptOut
                )
        })
    }, [exhaustPercent, actionOnExhaust])

    return (
        <Grid classes={{ root: 'quota_management--base--margin_1x' }}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} md={condition.revert ? 3 : 4}>
                    <Grid container spacing={0.5}>
                        {
                            !condition.revert && <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        label="% used"
                                        variant="outlined"
                                        value={exhaustPercent}
                                        type="number"
                                        onChange={handleChangeExhaustPercent}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        InputProps={{
                                            readOnly: readOnly,
                                            disabled: readOnly,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} md={condition.revert ? 12 : 8}>
                            <FormControl fullWidth size="small" error={actionOnExhaust === Actions.ActionUndefined}>
                                <InputLabel id="action-on-exhaust-label">Action</InputLabel>
                                <Select
                                    value={actionOnExhaust}
                                    onChange={handleChangeActionOnExhaust}
                                    labelId="action-on-exhaust-label"
                                    label="Action"
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                >
                                    {
                                        !condition.revert ? [
                                            <MenuItem 
                                            // disabled={!isAdmin} 
                                            disabled={true}
                                            value={Actions.ActionPauseServiceLine}>
                                                <Tooltip title={isAdmin ? "" : "Admin only action"} placement="top">
                                                    <ListItemText classes={{root: 'unset-margins'}}  primary="Pause Service Line" />
                                                </Tooltip>
                                            </MenuItem>,
                                             <MenuItem value={Actions.ActionNotification}>Send Notification</MenuItem>,
                                             <MenuItem value={Actions.ActionOptOut}>Mobile Priority (Opt Out)</MenuItem>
                                        ] : [
                                            <MenuItem disabled={!isAdmin} value={Actions.ActionResumeServiceLine}>
                                                <Tooltip title={isAdmin ? "" : "Admin only action"} placement="top">
                                                    <ListItemText classes={{root: 'unset-margins'}} primary="Resume Service Line" />
                                                </Tooltip>
                                            </MenuItem>,
                                            <MenuItem value={Actions.ActionNotification}>Send Notification</MenuItem>,
                                            <MenuItem value={Actions.ActionOptOut}>Mobile Priority (Opt Out)</MenuItem>
                                            
                                        ]
                                    }
                                </Select>
                                {
                                    actionOnExhaust === Actions.ActionUndefined ? <FormHelperText>Action is required</FormHelperText> : null
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={1} display={'flex'} gap={0.25} alignItems={'center'}>
                    {
                        condition.executed ? <Tooltip title={"Action executed in current quota period"} placement="top">
                            <CheckCircleIcon color={'success'} />
                        </Tooltip> : null
                    }
                    {removeComp}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(QuotaActions)