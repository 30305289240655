import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

const MultiColorBar = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.count, 0);

  return (
    <Box display="flex" width="80%" height="20px" border="1px solid #ddd">
      {data.map((item, index) => (
        <Box
          key={index}
          width={`${(item.count / total) * 100}%`}
          bgcolor={item.color}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title={<span style={{ fontSize: "12px" }}>{item.count ? item.count : 'no data'}</span>}>
          <Typography variant="body2" color="white" fontWeight="bold">
            {item.count}
          </Typography>
        </Tooltip>
        </Box>
      ))}
    </Box>
  );
};

export default MultiColorBar;
