import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CrossBarChart = (props) => {
    const { crossBarChartData, visibleSettings } = props;

    // Prepare series data for each severity
    const criticalData = visibleSettings.critical ? crossBarChartData.filter(item => item.severity === 'CRITICAL') : [];
    const highData = visibleSettings.high ? crossBarChartData.filter(item => item.severity === 'HIGH') : [];
    const mediumData = visibleSettings.medium ? crossBarChartData.filter(item => item.severity === 'MEDIUM') : [];
    const lowData = visibleSettings.low ? crossBarChartData.filter(item => item.severity === 'LOW') : [];

    // Combine categories based on visible data
    const combinedData = [...criticalData, ...highData, ...mediumData, ...lowData];
    const categories = combinedData.map(item => item.categories);

    // Align data to categories for each severity
    const alignDataToCategories = (data) => categories.map(category => {
        const item = data.find(item => item.categories === category);
        return item ? item.count : null;
    });

    const options = {
        chart: {
            type: 'bar',
            height: '360px',
            zoomType: 'x',
            plotBorderWidth: 1,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#D62728', '#FF7F0E', '#FFBF00', '#1F77B4'], // Colors for CRITICAL, HIGH, MEDIUM, LOW
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false,
            itemStyle: {
                fontWeight: '500',
                color: '#303030'
            },
            itemHoverStyle: {
                color: '#000'
            }
        },
        plotOptions: {
            series: {
                pointPadding: 0.55,  // Adjust to control the space between bars
                groupPadding: 0.55,
                pointWidth: 10,
                // Removed stacking for side-by-side bars
                colorByPoint: false,
                dataLabels: {
                    enabled: true,
                    align: 'left', // Align outside the bar
                    format: '{point.y}',  // Displays the value
                    style: {
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#000'  // Customize the color if needed
                    },
                    inside: false, // Ensure labels are outside the bar
                }
            },
            bar: {
                colorByPoint: false,
                // groupPadding: 0.75 // Adjust padding between bars
            }
        },
        tooltip: {
            pointFormat: '<span style="color: white">{series.name}</span>: <b>{point.y}</b>',
            valueDecimals: 2,
            borderWidth: 0.5,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#000',
            style: {
                color: '#fff',
            },
            shadow: true,
        },
        xAxis: {
            categories: categories,
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: '500',
                },
            },
            min: 0,
            max: categories.length - 1,
            scrollbar: {
                enabled: true
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                enabled: false
            },
            title: {
                text: 'Threat Count',
            }
        },
        series: [
            ...visibleSettings.critical ? [{
                name: 'Critical',
                data: alignDataToCategories(criticalData),
                color: '#D62728',
            }] : [],
            ...visibleSettings.high ? [{
                name: 'High',
                data: alignDataToCategories(highData),
                color: '#FF7F0E',
            }] : [],
            ...visibleSettings.medium ? [{
                name: 'Medium',
                data: alignDataToCategories(mediumData),
                color: '#FFBF00',
            }] : [],
            ...visibleSettings.low ? [{
                name: 'Low',
                data: alignDataToCategories(lowData),
                color: '#1F77B4',
            }] : [],
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CrossBarChart;
