
export type StarlinkQuotaTableSortBy = 'dp_id' | 'dp_name' | 'service_line_name' | 'current_usage' | 'limit' | 'status' | 'kit_numbers' | 'start_time' | 'period_display';

export interface StarlinkQuota {
    id: string;
    dp_id: string;
    dp_name: string;
    service_line_number?: string;
    service_line_name: string;
    kit_numbers: string;
    limit: number;
    period: number;
    period_unit: string;
    period_display?: string;
    start_time: string;
    actions?: Action[];
    current_quota_start_time?: string;
    current_usage: number
    used_percent?: string;
    span?: string;
    estimated_max_over_date?: string | null;
    status?: string;
    notification_methods?: string[],
    users_to_notify?: User[];
}

export interface Action {
    id: string;
    usage_percentage: number;
    type: ActionType;
    executed: boolean;
    valid?: boolean;
    revert?: boolean;
}

export interface User {
    name: string;
    email: string;
}

export type ActionType = 'pauseServiceLine' | 'resumeServiceLine' | 'sendNotification' | '' | 'optOut';

export const Actions = {
    ActionPauseServiceLine: 'pauseServiceLine',
    ActionResumeServiceLine: 'resumeServiceLine',
    ActionNotification: 'sendNotification',
    ActionOptOut: 'optOut',
    ActionUndefined: ''
} as const;