// Dialog Box for the filter action of Ticketing Page Pop-up
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  InputAdornment,
  Checkbox,
  Typography,
  Autocomplete,
  Paper,
  TextField,
  Divider,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import close from "../../asset/image/close.svg";
import DropdownIcon from "../../asset/image/Vector.png";
import OutlinedInput from '@mui/material/OutlinedInput';
import { MenuItem, Menu } from "@mui/material";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import './FilterTicketDialog.css';
import { DEFAULT_DATE_FORMAT, NO_TIMEFRAME, VIEW_LAST_WEEK, VIEW_LAST_MONTH, CUSTOM_DATES } from "../../utils/constants";
import moment from "moment-timezone";
import _ from "lodash";
import { autoCompleteRenderTags } from "../../utils/util";


const FilterTicketDialog = (props) => {
  const { onClose, selectedValue, open, isMoreFiltersActive, selection, moreFiltersOkClick, assigneeList, moreFiltersClearAllClick, authReducer } = props;

  let [dateFormat, setDateFormat]: any = useState();
  // let [isStatusOpen, setIsStatusOpen]: any = useState(false);
  // let [isStatusClosed, setIsStatusClosed]: any = useState(false);
  let [timeframeDropdownMenuAnchorEl, setTimeframeDropdownMenuAnchorEl]: any = React.useState(null);
  let openDateDropdownMenu = Boolean(timeframeDropdownMenuAnchorEl);
  let [dateRangeValue, setDateRangeValue]: any = React.useState("");
  let [openStatus, setOpenStatus]: any = useState(false);
  let [closedStatus, setClosedStatus]: any = useState(false);
  let [reopened, setReopened]: any = useState(false);
  let [onHold, setOnHold]: any = useState(false);
  let [escalated, setEscalated]: any = useState(false);
  let [resolved, setResolved]: any = useState(false);
  let [unAssigned, setUnAssigned]: any = useState(false);
  let [standUp, setStandUp]: any = useState(false);
  let [hotVessels, setHotVessels]: any = useState(false);
  let [raised24h, setRaised24h]: any = useState(false);
  let [myTickets, setMyTickets]: any = useState(false);
  let [critical, setCritical]: any = useState(false);
  const [RMA, setRMA]: any = useState(false);
  const timeframeList: any = [NO_TIMEFRAME, VIEW_LAST_WEEK, VIEW_LAST_MONTH, CUSTOM_DATES]
  let [selectedTimeframe, setSelectedTimeframe]: any = useState(timeframeList[0]);

  // Autocomplete
  const AUTO_COMPLETE_NO_OPTIONS_TEXT = "No options";
  const AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS = 1;
  let [creatorAutoCompleteSelectedvalues, setCreatorAutoCompleteSelectedvalues] = useState([]);
  let [assigneeAutoCompleteSelectedvalues, setAssigneeAutoCompleteSelectedvalues] = useState([]);

  useEffect(() => {
    // <DateRangePickerComponent format={dateFormat}
    setDateFormat(DEFAULT_DATE_FORMAT.toLowerCase());
  })

  useEffect(() => {
    if (open) {
      // get previously selected selections
      if (isMoreFiltersActive && selection) {
        setOpenStatus(selection.isTicketOpen);
        setClosedStatus(selection.isTicketClosed);
        setReopened(selection.isTicketReopened);
        setOnHold(selection.isTicketOnHold);
        setEscalated(selection.isTicketEscalated);
        setResolved(selection.isTicketResolved);
        setUnAssigned(selection.isUnassigned);
        setStandUp(selection.isStandUp);
        setHotVessels(selection.isHotVessel);
        setRaised24h(selection.isRaised24h);
        setMyTickets(selection.isMyTickets);
        setCritical(selection.isCritical);
        setRMA(selection.isTicketRMA);
        setAssigneeAutoCompleteSelectedvalues(selection.assignee)
        setCreatorAutoCompleteSelectedvalues(selection.creater)
        setSelectedTimeframe(selection.selectedTimeframe);
        console.log("SelectedTime Range",selection.selectedTimeframe)
        if (selection.selectedTimeframe == CUSTOM_DATES && selection.dateRangeValue && selection.dateRangeValue.includes("-")) {
          setDateRangeValue(selection.dateRangeValue);
        }
      } else {
        setDefaultSelections();
      }
    }
  }, [open])

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleClearAllClick = () => {
    setDefaultSelections();
  }

  const setDefaultSelections = () => {
    // setIsStatusOpen(false);
    // setIsStatusClosed(false);
    setOpenStatus(false);
    setClosedStatus(false);
    setReopened(false);
    setOnHold(false);
    setEscalated(false);
    setResolved(false);
    setRMA(false);
    setUnAssigned(false);
    setStandUp(false);
    setHotVessels(false);
    setRaised24h(false);
    setMyTickets(false);
    setCritical(false);
    setAssigneeAutoCompleteSelectedvalues([])
    setCreatorAutoCompleteSelectedvalues([])
    setSelectedTimeframe(timeframeList[0]);
    setDateRangeValue("");
  }

  
  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  function BpCheckbox(props) {
    return (
      <Checkbox
        
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

  const handleOkClick = () => {
    let startDate: any = "";
    let endDate: any = "";

   if(!_.isEmpty(selectedTimeframe)){ if (selectedTimeframe === VIEW_LAST_WEEK) {
      startDate = Date.now() - 7 * 24 * 3600 * 1000;
    } else if (selectedTimeframe === VIEW_LAST_MONTH) {
      startDate = Date.now() - 30 * 24 * 3600 * 1000;
    } else if (selectedTimeframe === CUSTOM_DATES && dateRangeValue.includes("-")) {
      startDate = dateRangeValue.split("-")[0].trim();
      endDate = dateRangeValue.split("-")[1].trim();
    }

    // CUSTOM_DATES
    if (selectedTimeframe === CUSTOM_DATES && dateRangeValue.includes("-")) {
      startDate = moment.utc(startDate).startOf('day').toISOString();
      endDate = moment.utc(endDate).endOf("day").toISOString();
    } else if (selectedTimeframe !== NO_TIMEFRAME && selectedTimeframe !== CUSTOM_DATES) {
      // for other timeframes except CUSTOM_DATES & NO_TIMEFRAME
      startDate = moment(startDate).utc().startOf('day').toISOString();
      endDate = moment(Date.now()).utc().endOf("day").toISOString();
    }}
    handleClose();
    moreFiltersOkClick(openStatus, closedStatus, reopened, onHold, escalated, resolved, selectedTimeframe, unAssigned, hotVessels, raised24h, critical, standUp, myTickets, RMA, startDate, endDate, dateRangeValue,assigneeAutoCompleteSelectedvalues,creatorAutoCompleteSelectedvalues);
  }

  const handleTimeframeDropdownMenu = (event: any) => {
    const _timeframeDropdownMenu: any = document.getElementById("timeframeDropdownMenu");
    if (_timeframeDropdownMenu && null != _timeframeDropdownMenu) {
      _timeframeDropdownMenu.style.zIndex = "1300";
    }
    setTimeframeDropdownMenuAnchorEl(event.currentTarget)
  }

  const closeTimeframeDropdownMenu = () => {
    setTimeframeDropdownMenuAnchorEl(null);
  };

  const handleTimeframeMenuItemClick = (item: any) => {
    setSelectedTimeframe(item);
    closeTimeframeDropdownMenu();
  }

  

  return (
    <div className="Form">
      <Dialog id={authReducer?.getDrawerOpen ? "ticket-pop-up1" : "ticket-pop-up2"}  className="tkt_more_filters_dialog menuScroll" open={open} style={{ display: "flex", justifyContent: "flex-start", left: "80px", top: "100px" }}>
        <DialogTitle className="dialog_title2 tickets-title inter-font-parent">
          <Grid className="filter-parent">
          <Grid className="filter-top">
            <Grid className="filter-head-parent">
              <span className="filter-header">
                Ticket Filters
              </span>
              <img src={close} onClick={() => handleClose()} className="dialog_closeIcon-filters" alt="Close" />
            </Grid>
          </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent className="pad-10">
        <div className="tkt_more_filters_dialog_status">
            <div className="tkt_more_filters_dialog_status_title marginBottom10px">FILTER IN</div>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={unAssigned}
                  onClick={(event) => {
                    setUnAssigned(!unAssigned);
                    setHotVessels(false);
                    setRaised24h(false);
                    setCritical(false)
                    setStandUp(false)
                    setMyTickets(false)
                    setRMA(false);
                  }}
                />
              </Grid>
              <Grid >
                <Typography className="typo">
                  Unassigned
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={hotVessels}
                  onClick={(event) => {
                    setUnAssigned(false);
                    setHotVessels(!hotVessels);
                    setRaised24h(false);
                    setCritical(false)
                    setStandUp(false)
                    setMyTickets(false)
                    setRMA(false);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Hot Sites
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={raised24h}
                  onClick={(event) => {
                    setUnAssigned(false);
                    setHotVessels(false);
                    setRaised24h(!raised24h);
                    setCritical(false)
                    setStandUp(false)
                    setMyTickets(false)
                    setRMA(false);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  {"Raised < 24h"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container className="gridflex">
            <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px", borderRadius: "5px" }}
                  checked={critical}
                  onClick={(event) => {
                
                    setUnAssigned(false);
                    setHotVessels(false);
                    setRaised24h(false);
                    setCritical(!critical);
                    setStandUp(false)
                    setMyTickets(false)
                    setRMA(false);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Critical
                </Typography>
              </Grid>
              </Grid>
              <Grid container className="gridflex">
                <Grid>
                  <BpCheckbox className="item-checkbox"
                    style={{ padding: "0px" }}
                    checked={standUp}
                    onClick={(event) => {

                      setUnAssigned(false);
                      setHotVessels(false);
                      setRaised24h(false);
                      setCritical(false);
                      setStandUp(!standUp);
                      setMyTickets(false)
                      setRMA(false);
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography className="typo">
                    StandUp
                  </Typography>
                </Grid>

              </Grid>
             
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px", borderRadius: "5px" }}
                  checked={myTickets}
                  onClick={(event) => {
                    setUnAssigned(false);
                    setHotVessels(false);
                    setRaised24h(false);
                    setCritical(false);
                    setStandUp(false);
                    setMyTickets(!myTickets);
                    setRMA(false);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  My Tickets
                </Typography>
              </Grid>

            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px", borderRadius: "5px" }}
                  checked={RMA}
                  onClick={(event) => {
                    setUnAssigned(false);
                    setHotVessels(false);
                    setRaised24h(false);
                    setCritical(false);
                    setStandUp(false);
                    setMyTickets(false);
                    setRMA(!RMA)
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  RMA
                </Typography>
              </Grid>

            </Grid>
          </div>
          <Divider />
          <div className="tkt_more_filters_dialog_status">
            <div className="tkt_more_filters_dialog_status_title marginBottom10px">Status</div>
            {/* <div className="tkt_more_filters_dialog_status_selection marginBottom10px">
              {
                isStatusOpen ? <div className="tkt_more_filters_dialog_status_selected tkt_more_filters_dialog_status_open" onClick={() => setIsStatusOpen(false)}>Open</div> : <div className="tkt_more_filters_dialog_status_unselected tkt_more_filters_dialog_status_open" onClick={() => setIsStatusOpen(true)}>Open</div>
              }
              {
                isStatusClosed ? <div className="tkt_more_filters_dialog_status_selected" onClick={() => setIsStatusClosed(false)}>Closed</div> : <div className="tkt_more_filters_dialog_status_unselected" onClick={() => setIsStatusClosed(true)}>Closed</div>
              }
            </div> */}
            {/* <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={<Checkbox 
          
          />}
          checked={openStatus}
          onClick={(event) => {
            setOpenStatus(!openStatus);
          }}
          label="Open"
          labelPlacement="start"
        />
        </FormGroup> */}
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px", borderRadius: "10px" }}
                  // inputProps={{style:{}}}
                  checked={openStatus}
                  onClick={(event) => {
                    setOpenStatus(!openStatus);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Open
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={closedStatus}
                  onClick={(event) => {
                    setClosedStatus(!closedStatus);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Closed
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={reopened}
                  onClick={(event) => {
                    setReopened(!reopened);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Reopened
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={onHold}
                  onClick={(event) => {
                    setOnHold(!onHold);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  On Hold
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px" }}
                  checked={escalated}
                  onClick={(event) => {
                    setEscalated(!escalated);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Escalated
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="gridflex">
              <Grid>
                <BpCheckbox className="item-checkbox"
                  style={{ padding: "0px", borderRadius: "5px" }}
                  checked={resolved}
                  onClick={(event) => {
                    setResolved(!resolved);
                  }}
                />
              </Grid>
              <Grid>
                <Typography className="typo">
                  Resolved
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="tkt_more_filters_dialog_status">
            <div className="tkt_more_filters_dialog_status_title marginBottom10px">Users</div>
            <div className="tkt_more_filters_dialog_status_title_two marginBottom10px">Assignee</div>
            <Autocomplete
            className="white"
              multiple
              limitTags={1}
              size="small"
              id="assigneeMenuSearchAutocomplete"
              noOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT}
              options={assigneeList}
              value={assigneeAutoCompleteSelectedvalues}
              defaultValue={assigneeAutoCompleteSelectedvalues}
              getOptionLabel={(option: any) => option["fullName"]}
              PaperComponent={({ children }) => (
                <Paper className="fontCSS fontSize14px fontWeightNormal" >{children}</Paper>
              )}
              onChange={(e, newValue: any) => {
                setAssigneeAutoCompleteSelectedvalues(newValue);
              }}
              renderTags={(value, getTagProps) => {
                return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "fullName");
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search assignee" />
              )}
            />

            <div className="tkt_more_filters_dialog_status_title_two marginBottom10px paddingTop15px">Creator</div>
            <Autocomplete
            className="white"
              multiple
              limitTags={1}
              size="small"
              id="creatorMenuSearchAutocomplete"
              noOptionsText={AUTO_COMPLETE_NO_OPTIONS_TEXT}
              options={assigneeList}
              value={creatorAutoCompleteSelectedvalues}
              defaultValue={creatorAutoCompleteSelectedvalues}
              getOptionLabel={(option: any) => option["fullName"]}
              PaperComponent={({ children }) => (
                <Paper className="fontCSS fontSize14px fontWeightNormal" >{children}</Paper>
              )}
              onChange={(e, newValue: any) => {
                setCreatorAutoCompleteSelectedvalues(newValue);
              }}
              renderTags={(value, getTagProps) => {
                return autoCompleteRenderTags(value, AUTO_COMPLETE_ON_FOCUS_LIMIT_TAGS, getTagProps, "fullName");
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search creator" />
              )}
            />
          </div>


          <Menu
            anchorEl={timeframeDropdownMenuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openDateDropdownMenu}
            onClose={closeTimeframeDropdownMenu}
            id="timeframeDropdownMenu"
            style={{ top: "30px", marginLeft: "14px", zIndex: -1 }}
          >
            {
              timeframeList.map((item: any, i: any) => (
                <MenuItem selected={item === selectedTimeframe ? true : false} className="timeframeDropdownMenuItem" onClick={() => handleTimeframeMenuItemClick(item)}>{item}</MenuItem>
              ))
            }
          </Menu>
        </ DialogContent>
        <DialogActions className="tickets-apply-btn">
          <Button className="ticketsClearFilterButton" onClick={handleClearAllClick}>Clear Filter</Button>
          <Button className="dialog_okButton" onClick={() => handleOkClick()} >Apply Filter</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});
export default withRouter(
  connect(mapStateToProps, {

  })(FilterTicketDialog)
);