export const SET_LOADING = 'set_loading';

export const SET_SIDE_MENU = "side_menu";
export const SET_USER_OU = "user_ou";
export const SET_SELECTED_OU = "selected_ou";
export const SET_SELECTED_VESSELS = "set_selected_vessels";
export const SET_FILTERED_VESSELS = "set_filtered_vessels";
export const SET_HUB_FILTERED_VESSELS = "set_hub_filtered_vessels";
export const SET_SELECTED_VESSEL = "set_selected_vessel"
export const SET_PERMISSION_OBSERVER = "set_permission_observer";
export const SET_IS_USER_OU_HAS_SUB_OUS = "is_user_ou_has_sub_ous";
export const SET_PARENT_AND_SUBDP_LIST = 'set_parent_and_subdp_list';
export const SET_READONLY_USER = 'set_readonly_user';
// Before login
export const LOGIN_USER = "login_user";
export const LOGOUT_USER = "logout_user";
export const VERIFY_USER = "verify_user";
export const RESET_PASSWORD = "reset_password";
export const RECOVERY_PASSWORD_USER = "recovery_password_user";
export const CHANGE_PASSWORD = "change_password";
export const OTP_GENERATION_STATUS="otp_generation_status";
export const IS_PRIVATE_ENABLED="is_private_enabled";
export const IS_PRIVATE_LOADING="is_private_loading";

// Summary
export const FETCH_VESSEL = "fetch_vessel";

//Account Management
export const SET_FETCH_USERS_LOADING = "set_fetch_users_loading";
export const FETCH_USERS = "fetch_users";
export const INVITE_USER = "invite_user";
export const INVITE_USER_LOADING = "invite_user_loading";
export const RESEND_INVITE = 'resend_invite';
export const RESEND_INVITE_LOADING = "resend_invite_loading";
export const GET_ORGANISATIONS = 'get_organisations';
export const GET_ORGANISATIONS_UNITS = "get_organisations_units";
export const SET_FETCH_OU_RESOURCES_LOADING = "set_fetch_ou_resources_loading";
export const FETCH_OU_RESOURCES = 'fetch_ou_resources';
export const OU_POLICY = "ou_policy";
export const USER_DETACH_LOADING = "user_detach_loading";
export const USER_DETACH = "user_detach";
export const USER_DELETE_LOADING = "user_delete_loading";
export const USER_DELETE = "user_delete";
export const DISPLAY_APPLICABLE_USER_POLICIES = "display_applicable_user_policies";
export const POLICIES_DETAILS = "policies_details";
export const UPDATE_USER_LOADING = "update_user_loading";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_ORGANIZATION_LOADING = "update_user_organization_loading";
export const UPDATE_USER_ORGANIZATION = "update_user_organization";
export const ENABLE_USER_ACCOUNT_LOADING = "enable_user_account_loading";
export const ENABLE_USER_ACCOUNT = "enable_user_account";
export const DISABLE_USER_ACCOUNT_LOADING = "disable_user_account_loading";
export const DISABLE_USER_ACCOUNT = "disable_user_account";
export const SET_PASSWORD_User_LOADING = "set_password_user_loading";
export const SET_PASSWORD_User = "set_password_user";
export const DETACH_RESOURCE_OU = "detach_resource_ou";
export const NOT_ATTACH_RESOURCE_OU = "not_attach_resource_ou";
export const ATTACH_RESOURCE_OU = "attach_resource_ou";
export const ALL_TEMPLATE = "all_template";
export const ACCOUNT_CREATE_SUB_DP = "account_create_sub_dp";
export const SET_SUB_DP_CREATE_LOADING = 'set_sub_dp_create_loading';
export const GET_SEARCH_DP_LIST = 'get_search_dp_list';
export const SET_DELETE_ORG_LOADING = 'set_delete_org_loading';
export const GET_DELETE_ORG = 'get_delete_org';
export const SET_MODIFY_ORG_LOADING = 'set_modify_org_loading';
export const GET_MODIFY_ORG = 'get_modify_org';
export const SET_DP_INFO_LOADING = 'set_dp_info_loading';
export const GET_DP_INFO = 'get_dp_info';
export const FETCH_SERVICE_LINES = 'fetch_service_lines';
 
// Service Management
export const GET_USER_POLICY = "get_user_policy";
export const SET_VESSEL_PLANS_LOADING = "set_vessel_plans_loading"
export const GET_VESSEL_PLANS = "get_vessel_plans";
export const OU_DERIVED = 'ou_derived';
export const SET_VESSEL_LISTING_LOADING = "set_vessel_listing_loading"
export const SET_PRIVATE_VESSEL_LIST = "set_private_vessel_list";
export const SET_PRIVATE_VESSEL_LIST_LOADING = "set_private_vessel_list_loading";

// Alert Page
export const SET_SEARCH_ALERTS_LOADING = "set_search_alerts_loading";
export const SEARCH_ALERTS = "search_alerts";
export const SET_VESSEL_ALERTS_LOADING = "vessel_alerts_loading";
export const GET_VESSEL_ALERTS = "vessel_alerts";
export const MARK_AS_FIXED_ALERT = "mark_as_fixed_alert";
export const ALERTS_NAME = "alerts_name";
export const ALERTS_HISTORY = "alerts_history";
export const DELETE_ALERT = "delete_alert"
export const DELETE_ALERT_LOADING = "delete_alert_loading"

// Dp Alert Page
export const GET_DP_ALERTS = "get_dp_alerts";
export const GET_DP_ALERTS_LOADING = "get_dp_alerts_loading"
export const UPDATE_DP_ALERT = "update_dp_alert";
export const UPDATE_DP_ALERT_LOADING = "update_dp_alert_loading"
export const UPDATE_DP_ALERT_NOTIFICATION = "update_dp_alert_notification"
export const UPDATE_DP_ALERT_NOTIFICATION_LOADING = "update_dp_alert_notification_loading"
export const DELETE_DP_ALERT_LINK_AVAILABILITY = "delete_dp_alert_link_availability"
export const DELETE_DP_ALERT_LINK_AVAILABILITY_LOADING = "delete_dp_alert_link_availability_loading"
export const GET_DP_ALERT_AUDIT_LOGS = "get_dp_alert_audit_logs"
export const GET_DP_ALERT_AUDIT_LOGS_LOADING = "get_dp_alert_audit_logs_loading"
export const UPDATE_DP_ALERT_STATUS_LOADING = "update_dp_alert_status"
export const UPDATE_DP_ALERT_STATUS = "update_dp_alert_status_loading"

// Site Alert Page
export const GET_SITE_ALERTS = "get_site_alerts";
export const GET_SITE_ALERTS_LOADING = "get_site_alerts_loading"
export const UPDATE_SITE_ALERT = "update_site_alert";
export const UPDATE_SITE_ALERT_LOADING = "update_site_alert_loading"
export const UPDATE_SITE_ALERT_NOTIFICATION = "update_site_alert_notification"
export const UPDATE_SITE_ALERT_NOTIFICATION_LOADING = "update_site_alert_notification_loading"
export const DELETE_SITE_ALERT_LINK_AVAILABILITY = "delete_site_alert_link_availability"
export const DELETE_SITE_ALERT_LINK_AVAILABILITY_LOADING = "delete_site_alert_link_availability_loading"
export const GET_SITE_ALERT_AUDIT_LOGS = "get_site_alert_audit_logs"
export const GET_SITE_ALERT_AUDIT_LOGS_LOADING = "get_site_alert_audit_logs_loading"
export const UPDATE_SITE_ALERT_STATUS = "update_site_alert_status"
export const UPDATE_SITE_ALERT_STATUS_LOADING = "update_site_alert_status_loading"

// Edge Administration
export const GET_VESSEL_EDGE_INFO = "get_vessel_edge_info";

// Ticketing 
export const SET_TICKETS_LOADING = "set_tickets_loading";
export const GET_TICKETS = "get_tickets";
export const GET_SIM_BOX = "get_sim_box";
export const GET_SIM_BOXES = "get_sim_boxes";
export const GET_SIM_BOXES_LOADING = "get_sim_boxes_loading";
export const GET_SIM_BOXES_COUNT = "get_sim_boxes_count";
export const GET_LTE_PROVIDERS = "get_lte_providers";
export const GET_DATA_USAGE = "get_data_usage";
export const GET_DATA_USAGE_COUNT = "get_data_usage_count";
export const GET_DAILY_DATA_USAGE = "get_daily_data_usage";
export const GET_GRAPHICAL_DATA_USAGE = "get_graphical_data_usage";
export const SET_CELLULAR_DATA_USAGE_LOADING = 'set_cellular_data_usage_loading';
export const GET_CELLULAR_DATA_USAGE = 'get_cellular_data_usage'
export const GRAPHICAL_DATA_USAGE_LOADING = "graphical_data_usage_loading";
export const EXPORT_DATA_USAGE_REPORT = "export_data_usage_report";
export const GET_USAGE_VESSEL_LIST = "get_usage_vessel_list";
export const GET_TICKET_DETAILS = "get_ticket_details";
export const SET_TICKET_DETAILS_LOADING = "set_ticket_details_loading";
export const SEND_TICKET_EMAIL = "send_ticket_email";
export const GET_HOT_VESSELS = "get_hot_vessels"
export const GET_TICKET_COMMENTS = "get_ticket_comments";
export const SET_TICKET_COMMENTS_LOADING = "set_ticket_comments_loading";
export const GET_TICKET_DETAILS_FIELD = "get_ticket_details_fields";
export const SET_TICKET_DETAILS_FIELD_LOADING = "set_ticket_details_fields_loading";
export const GET_TICKET_ATTACHMENTS = "get_ticket_attachments";
export const SET_TICKET_ATTACHMENTS_LOADING = "set_ticket_attachments_loading";
export const GET_SINGLE_TICKET_ATTACHMENTS = "get_single_ticket_attachments";
export const UPDATE_TICKET = "update_ticket";
export const CREATE_NEW_TICKET = "create_new_ticket";
export const ADD_ATTACHMENTS_TO_TICKET = "add_attachments_to_ticket"
export const SEND_TICKET_COMMENT = "send_ticket_comment";
export const GET_TICKET_HISTORY = "get_ticket_history";
export const SET_TICKET_HISTORY_LOADING = "set_ticket_history_loading";
export const GET_TICKET_AGENTS_BY_NAME = "get_ticket_agents_by_name";
export const GET_TICKET_ALL_AGENTS = "get_ticket_all_agents";
export const GET_DP_DATA = "get_dp_data";

// FIREWALL REPORTS
export const GET_FIREALL_WIDGETS = "get_firewall_widgets";
export const GET_FIREWALL_ACTIONS_TODAY_GRAPH = "get_firewall_actions_today_graph";
export const GET_FIREWALL_RULE_NAME_GRAPH = "get_firewall_rule_name_graph";
export const GET_FIREWALL_TABLE_DATA = "get_firewall_table_data";
export const GET_FIREWALL_RULE_NAME_FILTER = "get_firewall_rule_name_filter";
export const GET_FIREWALL_TABLE_DATA_COUNT = "get_firewall_table_data_count";
export const GET_FIREWALL_LINE_CHART = "get_firewall_line_chart";

export const SET_LOADING_FIREWALL_WIDGETS = "set_loading_firewall_widgets";
export const SET_LOADING_FIREWALL_RISTRICT_PIECHART = "set_loading_firewall_ristrict_piechart";
export const SET_LOADING_FIREWALL_PIE_RULENAME = "set_loading_firewall_pie_rulename";
export const SET_LOADING_FIREWALL_RULE_FILTER = "set_loading_firewall_rule_filter";
export const SET_LOADING_FIREWALL_TABLE_DATA = "set_loading_firewall_table_data";
export const SET_LOADING_FIREWALL_COUNTS = "set_loading_firewall_counts";
export const SET_LOADING_FIREWALL_LINECHART = "set_loading_firewall_linechart";

// DASHBOARD
export const SET_VESSEL_DASHBOARD_LOADING = 'set_vessel_dashboard_loading';
export const GET_VESSEL_SUMMARY_DASHBOARD = "get_vessel_Summary_dashboard";
export const GET_WIDGEY_SUMMARY_DASHBOARD = "get_widget_summary_dashboard";
export const GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM = "get_top_widget_wanlink_status_new_summ";
export const GET_WIDGET_LINK_STATUS_SUMMARY_DASHBOARD = "get_widget_link_status_summary_dashboard";
export const GET_VESSEL_SUMMARY_TABLE_COUNT="get_vessel_summary_table_count"
export const CLEAR_SITE_DASHBOARD = "clear_site_dashboard";
export const GET_NEW_SUMMARY_USAGE_TABLE= "get_new_summary_usage_table";
export const GET_NEW_SUMMARY_USAGE_TABLE_LOADING= "get_new_summary_usage_table_loading";
export const GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY = "get_new_summary_uptime_all_link_availablity";
export const GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY_LOADING = "get_new_summary_uptime_all_link_availablity_loading";
export const SET_VESSEL_TABLE_COUNT_LOADING = "get_vessel_table_count_loading";
export const SET_VESSEL_WAN_LINK_STATUS_LOADING = "set_vessel_wan_link_status_loading"
export const SPORTS_SITE_SUMMARY_COUNT_LOADING = "sports_site_summary_count_loading"

export const SET_VESSEL_SUMMARY_LOADING = 'set_vessel_summary_loading';
export const GET_VESSEL_SUMMARY_TABLE = "get_vessel_summary_table";

export const GET_VESSEL_LOC_WITH_ALERTS_LOADING = 'get_vessel_loc_with_alerts_loading';
export const GET_VESSEL_LOC_WITH_ALERTS = "get_vessel_loc_with_alerts";

export const GET_SYSTEM_INVENTORY = "get_system_inventory";
export const GET_EDGE_SERVER_STATUS = "get_edge_server_status";
export const GET_EDGE_SERVER_STATUS_LOADING = "edge_server_status_loading";

// DASHBOARD SINGLESTORE
export const SET_LINK_STATUS_PER_DEVICE_LOADING = "get_link_status_per_device_loading";
export const GET_LINK_STATUS_PER_DEVICE = "get_link_status_per_device";
export const GET_TOP_WIDGET_AVAILABILITY = "get_top_widget_availability"
export const GET_TOP_WIDGET_DATA_NEW_SUMM = "get_top_widget_data_new_summ"


//
export const GET_USER_DETAILS = "get_user_details";
export const UPDATE_USER_PROFILE = "update_user_profile";
export const USER_PREFERENCES_LOADING = "user_preferences_loading";
export const UPDATE_USER_PREFERENCES = "update_user_preferences";
export const GET_USER_PREFERENCES = "get_user_preferences";
export const USER_TIMEZONE = "user_timezone";
export const RESET_STATE = "reset_state";
export const GET_VESSELS_LISTING = "get_vessels_listing"
export const GET_USER_LOGIN_HISTORY = "get_user_login_history"

// Edge-Topology
export const GET_VESSEL_EDGE_TOPOLOGY_PRODUCTS = "get_vessel_edge_topology_products";
export const GET_PRODUCT_EDGE_TOPOLOGY_DEVICES = "get_product_edge_topology_devices";
export const GET_VESSEL_SUMMARY_TOPOLOGY = "get_vessel_summary_topology";
export const UPDATE_NAME = "update_name";
export const VESSEL_ADDRESS_MMSI = "vessel_address_mmsi"
export const GET_VESSEL_INFO = "get_vessel_info";
export const UPDATE_MMSI_AND_STREETADDRESS = "update_mmsi_and_streetaddress";
export const GET_PARENT_DEVICE_OF_A_SITE = "get_parent_device_of_a_site";
export const UPDATE_PARENT_DEVICE_OF_A_SITE = "update_parent_device_of_a_site";
export const REBOOT_DEVICE = "reboot_device";

// SOFTWARE LISTING
export const SOFTWARE_PRODUCT = "software_product";
export const SET_SOFTWARE_LISTING_LOADING = "set_software_listing";
export const SOFTWARE_LISTING = "software_listing";
export const GET_SOFTWARE_VERSIONS_DEVICE_COUNT = "get_software_versions_device_count";
export const GET_SOFTWARE_VERSION_DEVICE_DETAILS = "get_software_version_device_details";
export const GET_SOFT_VERSION_DEVICE_DETAILS = "get_soft_version_device_details";
export const GET_KONNECT_APP_DOWNLOAD_LINKS = "get_konnect_app_download_links";
export const GET_VX_BUILD_DETAILS = "get_vx_build_details";

export const GET_CONFIGURED_ALERTS_FOR_DEVICE = "get_configured_alerts_for_device";
export const UPDATE_CREATE_DEVICE_ALERT_CONFIGURATION = "update_create_device_alert_configuration";
export const UPDATE_DELETE_DEVICE_ALERT_CONFIGURATION = "update_delete_device_alert_configuration";
export const UPDATE_DEVICE_ALERT_CONFIGURATION_SETTINGS = "update_device_alert_configuration_settings";
export const GET_DP_DOCUMENTS_LIST = "get_dp_documents_list";

//Edge Events
export const SET_EDGE_EVENTS_LOADING = "set_edge_events_loading";
export const SET_EDGE_EVENTS_FILTER_LOADING = 'set_edge_events_filter_loading';
export const SET_EDGE_EVENTS_TABLE_LOADING = 'set_edge_events_table_loading';
export const SET_EDGE_EVENTS_TABLE_COUNT_LOADING = 'set_edge_events_table_count_loading';
export const GET_EDGE_EVENTS = "get_edge_events"
export const GET_EDGE_EVENTS_FILTER = "get_edge_events_filter"
export const EDGE_EVENTS = "edge_events"
export const GET_EDGE_EVENTS_TABLE = "get_edge_events_table";
export const GET_EDGE_EVENTS_TABLE_COUNT = "get_edge_events_table_count";
export const DOWNLOAD_EDGE_EVENTS_TABLE = "download_edge_events_table";
export const CLEAR_EDGE_EVENTS = "clear_edge_events";

// Site Charts
export const GET_SITE_RSSI = "get_site_rssi";
export const GET_SITE_SINR = "get_site_sinr";
export const GET_SITE_DATA_RATE = "get_site_data_rate";
export const GET_SITE_LINK_STATUS = "get_site_link_status";
export const GET_SITE_LATENCY_JITTER = "get_site_latency_jitter";
export const GET_SITE_THROUGHPUT = "get_site_throughput";
export const GET_SITE_INTERFACES = 'get_site_interfaces';
export const CLEAR_SITE_CHARTS = 'clear_site_charts';
export const DELETE_SITE_CHART_SERIES = 'delete_site_chart_series';
export const CLEAR_SITE_CHART_EVENTS = 'clear_site_chart_events';
export const GET_SITE_VSAT_SNR = 'get_site_vsat_snr'
export const GET_SITE_VSAT_TXPOWER = 'get_site_vsat_txpower'
export const GET_SITE_VSAT_POWER = 'get_site_vsat_power'
export const GET_SITE_VSAT_SYMBOL_RATE = 'get_site_vsat_symbol_rate'
export const GET_SITE_CLIENTS = 'get_site_clients'
export const GET_SITE_USAGE = 'get_site_usage'
export const GET_SITE_SYSTEM_UPTIME = 'get_site_system_uptime'
export const SET_SITE_CHARTS_RSSI_LOADING = 'set_site_charts_rssi_loading';
export const SET_SITE_CHARTS_SINR_LOADING = 'set_site_charts_sinr_loading';
export const SET_SITE_CHARTS_LINK_STATUS_LOADING = 'set_site_charts_link_status_loading';
export const SET_SITE_CHARTS_LATENCY_JITTER_LOADING = 'set_site_charts_latency_jitter_loading';
export const SET_SITE_CHARTS_THROUGHPUT_LOADING = 'set_site_charts_throughput_loading';
export const SET_SITE_CHARTS_VSAT_SNR_LOADING = 'set_site_charts_vsat_snr_loading';
export const SET_SITE_CHARTS_VSAT_TXPOWER_LOADING = 'set_site_charts_vsat_txpower_loading';
export const SET_SITE_CHARTS_VSAT_POWER_LOADING = 'set_site_charts_vsat_power_loading';
export const SET_SITE_CHARTS_VSAT_SYMBOL_RATE_LOADING = 'set_site_charts_vsat_symbol_rate_loading';
export const SET_SITE_CHARTS_SITE_INTERFACES_LOADING = 'set_Site_Charts_Site_Interfaces_Loading';
export const SET_SITE_CHARTS_SITE_DATA_RATE_LOADING = 'set_site_charts_site_data_rate_loading';
export const SET_SITE_CLIENTS_LOADING = 'set_site_clients_loading';
export const SET_SITE_USAGE_LOADING = 'set_site_usage_loading';
export const SET_SITE_SYSTEM_UPTIME_LOADING = 'set_site_system_uptime_loading';
export const SET_SITE_LAN_STATUS_LOADING = 'set_site_lan_status_loading';
export const GET_SITE_LAN_STATUS = 'get_site_lan_status';
export const SET_HUB_CLIENT_SDWAN_FLOWS_LOADING = 'set_hub_client_sdwan_flows_loading';
export const GET_HUB_CLIENT_SDWAN_FLOWS = 'get_hub_client_sdwan_flows';
export const SET_HUB_CLIENT_SDWAN_SPEED_LOADING = 'set_hub_client_sdwan_speed_loading';
export const GET_HUB_CLIENT_SDWAN_SPEED = 'get_hub_client_sdwan_speed';
export const SET_HUB_CLIENT_SDWAN_LATENCY_LOADING = 'set_hub_client_sdwan_latency_loading';
export const GET_HUB_CLIENT_SDWAN_LATENCY = 'get_hub_client_sdwan_latency';
export const SET_HUB_CLIENT_SDWAN_STATUS_LOADING = 'set_hub_client_sdwan_status_loading';
export const GET_HUB_CLIENT_SDWAN_STATUS = 'get_hub_client_sdwan_status';
export const SET_SDWAN_SPEEDTEST_LOADING = 'set_sdwan_speedtest_loading';
export const GET_SDWAN_SPEEDTEST = 'get_sdwan_speedtest';

//Inventory
export const SET_INVENTORY_LISTING_LOADING = "set_inventory_listing"
export const GET_INVENTORY_DEPLOYED_DATA = "get_inventory_deployed_data";
export const GET_INVENTORY_WAREHOUSE_TABLE_DATA = "get_inventory_warehouse_table_data";
export const INVENTORY_WAREHOUSE_CHANGE_GROUP = "inventory_warehouse_change_group";
export const GET_INVENTORY_WAREHOUSE_VESSEL_LIST = "get_inventory_warehouse_vessel_list";
export const ASSIGN_INVENTORY_TO_VESSEL = "assign_inventory_to_vessel";
export const GET_LICENSE_TYPES = "get_license_types"
export const GET_DPS_DEFAULT_ACTIVE_CONFIG = "get_dps_default_active_config"
export const CREATE_AND_ASSIGN_INVENTORY = "create_and_assign_inventory";
export const GET_DP_LIST = "get_dp_list";
export const GET_INVENTORY_DEPLOYED_DATA_COUNT = "get_inventory_deployed_data_count";
export const INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE = "inventory_assign_device_to_warehouse"
export const INVENTORY_DELETE_SITE = "inventory_delete_site"
export const INVENTORY_TOP_WIDGETS_REGISTERED_COUNT="INVENTORY_TOP_WIDGETS_REGISTERED_COUNT"
export const INVENTORY_TOP_WIDGET_KAAS_COUNT="inventory_top_widget_kaas_count"
export const INVENTORY_TOP_WIDGETS_WAREHOUSE_COUNT= "inventory_top_widgets_warehouse_count"
export const INVENTORY_DOWNLOAD_CONFIG = 'inventory-download_config';
export const INVENTORY_CHANGE_GROUP = 'inventory_change_group';
export const DOWNLOAD_INVENTORY_DEPLOYED_DATA = "download_inventory_deployed_data";
export const GET_CONFIG_PRODUCTS = 'get_config_products';
export const GET_WAN_PROFILE_LIST = 'get_wan_profile_list';
export const GET_POP_IPS_LIST = "get_pop_ips_list";
export const APPLY_GOLDEN_CONFIG = 'apply_golden_config';
export const APPLY_TRAFFIC_POLICY = 'apply_traffic_policy';
export const APPLY_FIREWALL_RULE = "apply_firewall_rule";
export const APPLY_WAN_PROFILE = "apply_wan_profile";
export const APPLY_HUB_IP_CONFIG = "apply_hub_ip_config";
export const BACKUP_CONFIG_NOW = "backup-config-now";
export const DEVICEWISE_LATEST_EOS_VERSION = "devicewise_latest_eos_version";
export const UPGRADE_DEVICE_TO_LATEST_EOS_VERSION = "upgrade_device_to_latest_eos_version";
export const GET_DEVICE_LIST = "get_device_list";
export const CREATE_INVENTORY_GROUP="create_group";
export const GET_INVENTORY_GROUP_LIST = "get_inventory_group_list";
export const GET_TRAFFIC_POLICY_LIST = "get_traffic_policy_list";
export const GET_TRAFFIC_POLICY_LISTING_LOADING = "get_traffic_policy_listing_loading";
export const GET_WAN_PROFILE_LISTING_LOADING = "get_wan_profile_listing_loading";
export const GET_POP_IPS_LISTING_LOADING = "get_pop_ips_listing_loading";
export const APPLY_TRAFFIC_POLICY_LOADING = "apply_traffic_policy_loading";
export const GET_TRAFFIC_POLICY_LOG_LIST_LOADING = "get_traffic_policy_log_list_loading";
export const APPLY_FIREWALL_RULE_LOADING = "apply_firewall_rule_loading";
export const APPLY_WAN_PROFILE_LOADING = "apply_wan_profile_loading";
export const APPLY_HUB_IP_CONFIG_LOADING = "apply_hub_ip_config_loading";
export const GET_TRAFFIC_POLICY_LOG_LIST = "get_traffic_policy_log_list";
export const GET_INVENTORY_GROUP_LIST_COUNT = "get_inventory_group_list_count"; 
export const GET_SITE_INFO_LIST = "get_site_info_list";
export const GET_SITE_INFO_LIST_COUNT = "get_site_info_list_count";
export const UPDATE_INVENTORY_GROUP="update_group";
export const DELETE_INVENTORY_GROUP="delete_group";
export const INVENTORY_GROUP_DEVICES_EOS_UPGRADE="inventory_group_devices_eos_upgrade'";
export const SET_DEVICE_HISTORY_LOADING = 'set_device_history_loading';
export const GET_DEVICE_HISTORY = 'get_device_history';
export const SET_UPGRADE_INVENTORY_LICENSE_LOADING = 'set_upgrade_inventory_license_loading';
export const GET_UPGRADE_INVENTORY_LICENSE = 'get_upgrade_inventory_license';

//High Availability
export const HIGH_AVAILABILITY = "high_availability";
export const GET_HIGH_AVAILABILITY_DEVICE = "get_high_availability_device";
export const CREATE_HA = "create_ha";
export const SYNC_DEVICE = "sync";
export const UPDATE_DEVICE = "update";
export const DELETE_HIGH_AVAILABILITY = "delete_high_availability";

//Activity Reports
export const SET_ACTIVITY_REPORTS_LOADING = "set_activity_reports_loading";
export const SET_ACTIVITY_REPORTS_APPS_LOADING = 'set_activity_reports_apps_loading';
export const SET_ACTIVITY_REPORTS_VLANS_LOADING = 'set_activity_reports_vlans_loading';
export const GET_ALL_USERNAMES = "get_all_usernames";
export const GET_USAGE_WIDGETS =   "get_usage_widgets";
export const GET_USAGE_TABLE_DATA = "get_usage_table_data";
export const GET_LINE_CHART_USAGE = "get_line_chart_usage";
export const GET_CATEGORY_PIE_CHART_USAGE = "get_category_pie_chart_usage";
export const GET_TOP_APPLICATION_LINE_CHART = "get_top_application_line_chart";
export const GET_TOP_SITES_TABLE_DATA = "get_top_sites_table_data";
export const GET_TOP_APP_TOP_BLOCKED_PIE_CHART = "get_top_app_top_blocked_pie_chart";
export const SET_ACTIVITY_REPORTS_WIDGET_LOADING = "set_activity_reports_widget_loading";
export const SET_ACTIVITY_REPORTS_CATEGORY_PIE_LOADING = "set_activity_reports_category_pie_loading";
export const SET_ACTIVITY_REPORTS_APPLICATION_PIE_LOADING = "set_activity_reports_application_pie_loading";
export const SET_ACTIVITY_REPORTS_USAGE_LINE_CHART_LOADING = "set_activity_reports_usage_line_chart_loading";
export const SET_ACTIVITY_REPORTS_TOP_APP_LINE_CHART_LOADING = "set_activity_reports_top_app_line_chart_usage";
export const SET_ACTIVITY_REPORTS_TOP_USERS_LOADING = "set_activity_reports_top_users_loading";
export const SET_ACTIVITY_REPORTS_TOP_SITES_LOADING = "set_activity_reports_top_sites_loading";
export const GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_APPLICATION = "get_top_download_upload_blocked_application";
export const GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_DOMAIN = "get_top_download_upload_blocked_domain";
export const GET_TOP_DOWNLOAD_UPLOAD_BLOCKED_IP = "get_top_download_upload_blocked_ip";
export const SET_ACTIVITY_REPORTS_BOTTOM_TABLE_LOADING = "set_activity_reports_bottom_table_loading";
export const SET_ACTIVITY_REPORTS_BOTTOM_TABLE_DOMAIN_LOADING = "set_activity_reports_bottom_table_domain_loading";
export const SET_ACTIVITY_REPORTS_BOTTOM_TABLE_IP_LOADING = "set_activity_reports_bottom_table_ip_loading";
export const GET_TOP_BLOCKED_APP_BOTTOM_TABLE = "get_top_blocked_app_bottom_table";
export const SET_REPORTS__TOP_BLOCKED_APP_BOTTOM_TABLE_LOADING = "set_reports_top_blocked_app_bottom_table";
export const GET_ACTIVITY_REPORTS_CONTENT_FILTERS_TABLE = 'get_activity_reports_content_filters_table';
export const GET_ALL_APPLICATIONS = 'get_all_applications';
export const GET_ALL_VLANS = 'get_all_vlans';
export const GET_DPI_REPORTS_USER_MAX_RISK_TYPES = 'get_dpi_reports_user_max_risk_types';
export const SET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK = 'set_dpi_reports_top_widget_allsite_risk';
export const GET_DPI_REPORTS_TOP_WIDGET_ALLSITE_RISK = 'get_dpi_reports_top_widget_allsite_risk';
export const GET_DPI_REPORTS_TOP_WIDGET_SINGLESITE_RISK = 'get_dpi_reports_top_widget_singlesite_risk';
export const SET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK = 'set_dpi_reports_pie_chart_allsite_risk';
export const GET_DPI_REPORTS_PIE_CHART_ALLSITE_RISK = 'get_dpi_reports_pie_chart_allsite_risk';
export const GET_DPI_REPORTS_PIE_CHART_SINGLESITE_RISK = 'get_dpi_reports_pie_chart_singlesite_risk';

// Generated Reports
export const CREATE_DOWNLOAD_SERVICE = 'create_download_service';
export const SET_GENERATED_REPORTS_TABLE_LOADING = 'set_generated_reports_table_loading';
export const SET_GENERATED_REPORTS_TABLE_COUNT_LOADING = 'set_generated_reports_table_count_loading';
export const GET_GENERATED_REPORTS_TABLE = 'get_generated_reports_table_loading';
export const GET_GENERATED_REPORTS_TABLE_COUNT = 'get_generated_reports_table_count_loading';
export const CREATE_DOWNLOAD_SERVICE_FIREWALL = 'create_download_service_firewall';
export const DELETE_REPORT_SERVICE = 'delete_report_service';
export const CREATE_DOWNLOAD_SERVICE_CONTENT_FILTER = "create_download_service_content_filter";

// IAM Login History
export const SET_IAM_LOGIN_HISTORY_TABLE = 'set_iam_login_history_table';
export const SET_IAM_LOGIN_HISTORY_TABLE_COUNT = 'set_iam_login_history_table_count';
export const SET_IAM_LOGIN_HISTORY_TOP_WIDGET = 'set_iam_login_history_top_widget';
export const SET_IAM_LOGIN_HISTORY_FILTER_USER = 'set_iam_login_history_filter_user';
export const SET_IAM_LOGIN_HISTORY_FILTER_APP = 'set_iam_login_history_filter_app';
export const SET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST = 'set_iam_login_history_filter_desc_list';
export const SET_IAM_LOGIN_HISTORY_TIMESERIES_CHART = 'set_iam_login_history_timeseries_chart';
export const GET_IAM_LOGIN_HISTORY_TABLE = 'get_iam_login_history_table';
export const GET_IAM_LOGIN_HISTORY_TABLE_COUNT = 'get_iam_login_history_table_count';
export const GET_IAM_LOGIN_HISTORY_TOP_WIDGET = 'get_iam_login_history_top_widget';
export const GET_IAM_LOGIN_HISTORY_FILTER_USER = 'get_iam_login_history_filter_user';
export const GET_IAM_LOGIN_HISTORY_FILTER_APP = 'get_iam_login_history_filter_app';
export const GET_IAM_LOGIN_HISTORY_TIMESERIES_CHART = 'get_iam_login_history_timeseries_chart';
export const GET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST = 'get_iam_login_history_filter_desc_list';

// Audit Logs
export const AUDIT_LOGS = "audit_logs";
//white label
export const DOMAIN_NAME="domain_name";
export const DOMAIN_CONFIG="domain_config"

// Config
export const GET_BACKUP_CONFIG_DATA = "get_backup_config_data";
export const COPY_CONFIG_TO_MANUAL_BACKUP = "copy_config_to_manual_backup";
export const DELETE_MANUAL_BACKUP_CONFIG= "delete_manual_backup_config"
export const GET_DEFAULT_CONFIG_LISTING = 'get_default_config_listing';
export const EDIT_DEFAULT_CONFIG = 'edit_default_config';
export const CREATE_DEFAULT_CONFIG = 'create_default_config';
export const DELETE_DEFAULT_CONFIG = 'delete_default_config';
export const GET_DEFAULT_PRODUCTS = 'get_default_products';
export const GET_CONFIG_SOFTWARE_VERSIONS = 'get_config_software_versions';
export const GET_CONFIG_STATUS = 'get_config_status';
export const GET_GROUP_TRANSCATION_CONFIG_STATUS = "get_group_transcation_config_status";

// Maintenance
export const GET_MAINTENANCE_INFO = "get_maintenance_info";

// Group Settings
export const GET_GROUP_SETTINGS_DATA = "GET_GROUP_SETTINGS_DATA";
export const UPDATE_GROUP_SETTINGS_DATA = "UPDATE_GROUP_SETTINGS_DATA";

// Content Filter
export const SET_CF_TOP_USERS_TABLE_LOADING = 'set_cf_top_users_table_loading';
export const SET_CF_TOP_USERS_TABLE_COUNT_LOADING = 'set_cf_top_users_table_count_loading';
export const SET_CF_TOP_SITES_TABLE_LOADING = 'set_cf_top_sites_table_loading';
export const SET_CF_TOP_SITES_TABLE_COUNT_LOADING = 'set_cf_top_sites_table_count_loading';
export const SET_CF_CATEGORY_PIE_LOADING = 'set_cf_category_pie_loading';
export const SET_CF_APPLICATION_PIE_LOADING = 'set_cf_application_pie_loading';
export const SET_CF_SUBCAT_PIE_LOADING = 'set_cf_subcat_pie_loading';
export const SET_CF_LINECHART_LOADING = 'set_cf_linechart_loading';
export const SET_CF_TW_TOP_SITE_LOADING = 'set_cf_tw_top_site_loading';
export const SET_CF_TW_TOP_USER_LOADING = 'set_cf_tw_top_user_loading';
export const SET_CF_TW_TOP_CATEGORY_LOADING = 'set_cf_tw_top_category_loading';
export const SET_CF_TW_TOP_DOMAIN_LOADING = 'set_cf_tw_top_domain_loading';
export const SET_CF_CATEGORY_LIST_LOADING = 'set_cf_category_list_loading';
export const SET_CF_APP_LIST_LOADING = 'set_cf_app_list_loading';
export const SET_CF_USER_LIST_LOADING = 'set_cf_user_list_loading';
export const SET_CF_VLAN_LIST_LOADING = 'set_cf_vlan_list_loading';
export const SET_CF_DEVICE_LIST_LOADING = 'set_cf_device_list_loading';
export const SET_CF_SUb_CATEGORY_LIST_LOADING = 'set_cf_sub_category_list_loading';
export const GET_CF_TOP_USERS_TABLE = 'get_cf_top_users_table';
export const GET_CF_TOP_USERS_TABLE_COUNT = 'get_cf_top_users_table_count';
export const GET_CF_TOP_SITES_TABLE = 'get_cf_top_sites_table';
export const GET_CF_TOP_SITES_TABLE_COUNT = 'get_cf_top_sites_table_count';
export const GET_CF_CATEGORY_PIE = 'get_cf_category_pie';
export const GET_CF_APPLICATION_PIE = 'get_cf_application_pie';
export const GET_CF_SUBCAT_PIE = 'get_cf_subcat_pie';
export const GET_CF_LINECHART = 'get_cf_linechart';
export const GET_CF_TW_TOP_SITE = 'get_cf_tw_top_site';
export const GET_CF_TW_TOP_USER = 'get_cf_tw_top_user';
export const GET_CF_TW_TOP_CATEGORY = 'get_cf_tw_top_category';
export const GET_CF_TW_TOP_DOMAIN = 'get_cf_tw_top_domain';
export const GET_CF_CATEGORY_LIST = 'get_cf_category_list';
export const GET_CF_APP_LIST = 'get_cf_app_list';
export const GET_CF_USER_LIST = 'get_cf_user_list';
export const GET_CF_VLAN_LIST = 'get_cf_vlan_list';
export const GET_CF_DEVICE_LIST = 'get_cf_device_list';
export const GET_CF_SUB_CATEGORY_LIST = 'get_cf_sub_category_list';
export const UPDATE_NEW_SUMMARY_START_DATE = 'update_new_summary_start_Date';
export const UPDATE_NEW_SUMMARY_END_DATE = 'update_new_summary_end_Date';
export const UPDATE_NEW_SUMMARY_WAN_TYPE = 'update_new_summary_wan_Type';

// Summary Dashboard
export const SET_DASHBOARD_PIE_USAGEPER_WANTYPE_LOADING = 'set_dashboard_pie_usageper_wantype_loading';
export const SET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE_LOADING = 'set_dashboard_histogram_usageper_wantype_loading';
export const SET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_LOADING = 'set_dashboard_histogram_usageper_site_loading';
export const SET_DASHBOARD_USAGE_PIE_TOP10_APPS_LOADING = 'set_dashboard_usage_pie*_top10_apps_loading';
export const SET_DASHBOARD_PIE_WAN_USAGE_BYSITE_LOADING = 'set_dashboard_pie_wan_usage_bysite_loading';
export const SET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK_LOADING = 'set_dashboard_usage_histogram_uplink_dlk_loading';
export const GET_DASHBOARD_PIE_USAGEPER_WANTYPE = 'get_dashboard_pie_usageper_wantype';
export const GET_DASHBOARD_HISTORGRAM_USAGEPER_WANTYPE = 'get_dashboard_histogram_usageper_wantype';
export const GET_DASHBOARD_HISTORGRAM_USAGEPER_SITE_COUNT = 'get_dashboard_histogram_usageper_site_count';
export const GET_DASHBOARD_USAGE_PIE_TOP10_APPS = 'get_dashboard_usage_pie*_top10_apps';
export const GET_DASHBOARD_PIE_WAN_USAGE_BYSITE = 'get_dashboard_pie_wan_usage_bysite';
export const GET_DASHBOARD_USAGE_HISOGRAM_UPLINK_DLK = 'get_dashboard_usage_histogram_uplink_dlk';
export const SET_DASHBOARD_USAGE_HISTOGRAM_BYSITE_LOADING = 'set_dashboard_usage_histogram_bysite_loading';
export const GET_DASHBOARD_USAGE_HISTOGRAM_BYSITE = 'get_dashboard_usage_histogram_bysite';

export const SET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE_LOADING = 'set_dashboard_uptime_sitestatistics_table_loading';
export const SET_DASHBOARD_UPTIME_LINECHART_BYWAN_LOADING = 'set_dashboard_uptime_linechart_bywan_loading';
export const SET_DASHBOARD_UPTIME_PIECHART_BYSITE_LOADING = 'set_dashboard_uptime_piechart_bysite_loading';
export const SET_DASHBOARD_UPTIME_PIECHART_BYWAN_LOADING = 'set_dashboard_uptime_piechart_bywan_loading';
export const SET_DASHBOARD_UPTIME_LINECHART_BYSITE_LOADING = 'set_dashboard_uptime_linechart_bysite_loading';
export const GET_DASHBOARD_UPTIME_SITESTATISTICS_TABLE = 'get_dashboard_uptime_sitestatistics_table';
export const GET_DASHBOARD_UPTIME_LINECHART_BYWAN = 'get_dashboard_uptime_linechart_bywan';
export const GET_DASHBOARD_UPTIME_PIECHART_BYSITE = 'get_dashboard_uptime_piechart_bysite';
export const GET_DASHBOARD_UPTIME_PIECHART_BYWAN = 'get_dashboard_uptime_piechart_bywan';
export const GET_DASHBOARD_UPTIME_LINECHART_BYSITE = 'get_dashboard_uptime_linechart_bysite';

export const SET_DASHBOARD_ALERT_PIECHART_BYNAME_LOADING = 'set_dashboard_alert_piechart_byname_loading';
export const GET_DASHBOARD_ALERT_PIECHART_BYNAME = 'set_dashboard_alert_piechart_byname';
export const SET_DASHBOARD_ALERT_PIECHART_BYSITE_LOADING = 'set_dashboard_alert_piechart_bysite_loading';
export const GET_DASHBOARD_ALERT_PIECHART_BYSITE = 'set_dashboard_alert_piechart_bysite';
export const SET_DASHBOARD_ALERT_LINECHART_BYNAME_LOADING = 'set_dashboard_alert_linechart_byname_loading';
export const GET_DASHBOARD_ALERT_LINECHART_BYNAME = 'set_dashboard_alert_linechart_byname';
export const SET_DASHBOARD_ALERT_LINECHART_BYSITE_LOADING = 'set_dashboard_alert_linechart_bysite_loading';
export const GET_DASHBOARD_ALERT_LINECHART_BYSITE = 'set_dashboard_alert_linechart_bysite';
export const SET_DASHBOARD_ALERT_TOPWIDGET_LOADING = 'set_dashboard_alert_topwidget_loading';
export const GET_DASHBOARD_ALERT_TOPWIDGET = 'get_dashboard_alert_topwidget';
export const SET_DASHBOARD_PER_DEVICE_WAN_PROFILES = 'set_dashboard_per_device_wan_profiles';
export const GET_DASHBOARD_PER_DEVICE_WAN_PROFILES = 'get_dashboard_per_device_wan_profiles';
export const SET_DASHBOARD_ADVANCED_BONDING_LOADING = 'set_dashboard_advanced_bonding_loading';
export const GET_DASHBOARD_ADVANCED_BONDING = 'get_dashboard_advanced_bonding';
export const SET_DASHBOARD_LAN_STATUS_LOADING = 'set_dashboard_lan_status_loading';
export const GET_DASHBOARD_LAN_STATUS = 'get_dashboard_lan_status';
export const SET_DASHBOARD_CONNECTED_HUB_LOADING = 'set_dashboard_connected_hub_loading';
export const GET_DASHBOARD_CONNECTED_HUB = 'get_dashboard_connected_hub';

// Threat Dashboard 
export const GET_THREAT_DASHBOARD_TOPWIDGET = "get_threat_dashboard_topwidget";
export const SET_THREAT_DASHBOARD_TOPWIDGET_LOADING = "set_threat_dashboard_topwidget_loading";
export const GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL = "get_threat_dashboard_topwidget_total";
export const SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING = "set_threat_dashboard_topwidget_total_loading";

export const GET_TOP_10_THREAT_BY_NAME = "get_top_10_threat_by_name";
export const SET_TOP_10_THREAT_BY_NAME_LOADING = "get_top_10_threat_by_name_loading";
export const GET_TOP_10_THREAT_BY_SEVERITY = "get_top_10_threat_by_severity"
export const SET_TOP_10_THREAT_BY_SERVERITY_LOADING= "get_top_10_threat_by_severity_loading";
export const GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY= "get_threat_dashboard_bar_chart_on_severity"
export const GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING= "get_threat_dashboard_bar_chart_on_severity_loading"
export const SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING= "set_threat_dashboard_top_10_threat_table_loading"
export const GET_TOP_10_THREAT_TABLE_DATA = "get_top_10_threat_table_data";
export const SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING= "set_threat_dashboard_all_threat_table_loading"
export const GET_ALL_THREAT_TABLE_DATA = "get_alll_threat_table_data";
export const SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING= "set_threat_dashboard_user_threat_table_loading"
export const GET_USER_THREAT_TABLE_DATA = "get_user_threat_table_data";
export const UPDATE_NEW_SEVERITY_TYPE="update_new_serverity_type"
export const GET_THREAT_SITE_LIST = "get_threat_site_list";
export const GET_THREAT_SITE_LIST_LOADING = "get_threat_site_list_loading";
export const GET_THREAT_DEVICE_LIST = "get_threat_device_list";
export const GET_THREAT_DEVICE_LIST_LOADING = "get_threat_device_list_loading";

//csv
export const GET_CSV_DATA_TO_DOWNLOAD = 'get_csv_data_to_download';

export const GET_DRAWER_OPEN = 'get_drawer_open';

//System health
export const SET_SYSTEM_HEALTH_DEVICES_LOADING = 'set_system_health_devices_loading';
export const GET_SYSTEM_HEALTH_DEVICES = 'get_system_health_devices';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU_LOADING = 'set_system_health_metrics_line_chart_cpu_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CPU = 'get_system_health_metrics_line_chart_cpu';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY_LOADING = 'set_system_health_metrics_line_chart_memory_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_MEMORY = 'get_system_health_metrics_line_chart_memory';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK_LOADING = 'set_system_health_metrics_line_chart_contrack_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_CONTRACK = 'get_system_health_metrics_line_chart_contrack';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS_LOADING = 'set_system_health_metrics_line_chart_lo_rx_drops_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_LO_RX_DROPS = 'get_system_health_metrics_line_chart_lo_rx_drops';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP_LOADING = 'set_system_health_metrics_line_chart_swap_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_SWAP = 'get_system_health_metrics_line_chart_swap';
export const SET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE_LOADING = 'set_system_health_metrics_line_chart_temperature_loading';
export const GET_SYSTEM_HEALTH_METRICS_LINE_CHART_TEMPERATURE = 'get_system_health_metrics_line_chart_temperature';
export const SET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART_LOADING = 'set_system_health_disk_storage_line_chart_loading';
export const GET_SYSTEM_HEALTH_DISK_STORAGE_LINE_CHART = 'get_system_health_disk_storage_line_chart';

// Hub
export const SET_HUB_DASHBOARD_HUB_DATA_LOADING = 'set_hub_dashboard_hub_data_loading';
export const GET_HUB_DASHBOARD_HUB_DATA = 'get_hub_dashboard_hub_data';
export const SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING = 'set_hub_dashboard_hub_data_count_loading';
export const GET_HUB_DASHBOARD_HUB_DATA_COUNT = 'get_hub_dashboard_hub_data_count';
export const SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING = 'set_hub_dashboard_client_table_loading';
export const GET_HUB_DASHBOARD_CLIENT_TABLE = 'get_hub_dashboard_client_table';
export const SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING = 'set_hub_dashboard_sites_dropdown_loading';
export const GET_HUB_DASHBOARD_SITES_DROPDOWN = 'get_hub_dashboard_sites_dropdown';
//starlink
export const UPDATE_NEW_SUMMARY_SERVICE_LINE = 'update_new_summary_service_line';
export const UPDATE_NEW_SUMMARY_SERVICE_LINE_QUERY_PARAM = 'update_new_summary_service_line_query_param';
