import React, { useEffect, useState } from "react";
import { Autocomplete, Divider, Button, Dialog, Grid, TextField, Typography, DialogTitle, DialogContent, DialogActions, Chip, Paper, Box, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItem, ListItemText } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { createSubOrg, createServiceLine } from "../slice";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
import { setLoading } from "../../../UserScreen/Starlink/slice";
import { getDecodeURI } from "../../../utils/util";




function CreateServiceLineDialog(props) {

    const { open, onClose, onSave, data, createServiceLine, location } = props;
    const params = getDecodeURI(location.search)
    const [selectedOu, setSelectedOu] = useState(params?.ouId)
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [addressArray, setAddressArray] = useState<any>([])
    const [availableProductsArray, setAvailableProductsArray] = useState([])
    const [customPlansArray, setCustomPlansArray] = useState([]);
    const [userTerminalArray, setUserTerminalArray] = useState([])
    const [filteredTerminalArray, setFilteredTerminalArray] = useState<any>([])
    const [selectedTerminals, setSelectedTerminals] = useState<any>([]);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false);
    const [formattedSubscriptionDetails, setFormattedSubscriptionDetails] = useState<any>([]);

    useEffect(() => {
        if (data?.custom_plans && data?.available_products) {
            setAddressArray([{ formatted_address: 'Add new address', id: 'new' }, ...data?.address])
            setAvailableProductsArray(data?.available_products)
            setCustomPlansArray(data?.custom_plans)
            setUserTerminalArray(data?.user_terminals)

            let customPlans = data?.custom_plans?.map((subscription, index) => ({
                ...subscription,
                label: `${subscription.plan_name} `,
                data: subscription?.details,
                hasDivider: data?.custom_plans?.length - 1 === index ? true : false
            }));

            let subsPlan = data?.available_products?.map((subscription, index) => ({
                ...subscription,
                label: `${subscription.name} • US $${subscription.price} • ${subscription.iso_currency_code}`,
                data: subscription?.plan_details,
                // hasDivider: customPlans.length
            }));

            setFormattedSubscriptionDetails([{label:"Custom Plans", name:"Custom Plans", data: []}, ...customPlans, {label:"Standard Plans", name:"Standard Plans", data: []}, ...subsPlan])
        }

    }, [data])

    const [formData, setFormData] = useState<any>({
        nickname: '',
        subscriptionType: null,
        serviceAddress: null,
        terminalId: null
    });

    const clearData = () => {
        setFormData({
            nickname: '',
            subscriptionType: null,
            serviceAddress: null,
            terminalId: null
        })
        setEmails([])
        setSelectedTerminals([])
        setLoading(false)
    }

    useEffect(() => {
        if (formData && formData?.subscriptionType && selectedAccount !== "") {
            let filteredTerminals = userTerminalArray.map((item: any) => ({
                dishSerialNumber: item.dish_serial_number,
                kitSerialNumber: item.kit_serial_number,
                userTerminal: item.user_terminal_id,
                accountNumber: item.account_number
            }));
            setFilteredTerminalArray(filteredTerminals)
        }

    }, [formData]);

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleSaved = () => {
        setDialogOpen(false)
        handleAddressSave(addressData)
    }

    const handleAddressSave = (addressData) => {
        setFormData({
            ...formData,
            serviceAddress: {
                    address_lines: addressData?.addressLines,
                    administrative_area_code:  addressData?.administrativeAreaCode,
                    formatted_address: addressData?.formattedAddress,
                    latitude: addressData?.latitude,
                    longitude: addressData?.longitude,
                    region_code:addressData?.regionCode,
                    locality:"",
                    administrative_area:"",
                    region:"",
                    postal_code:""
            },
        });
    };

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const [addressData, setAddressData] = useState({
        administrativeAreaCode: ``,
        regionCode: '',
        formattedAddress: ``,
        addressLines: [],
        latitude: '',
        longitude: ''

        // administrativeAreaCode: `England`,
        // regionCode: 'GB',
        // formattedAddress: `Harfrey's Rd, Great Yarmouth NR31, UK`,
        // addressLines: [`Harfrey's Road`],
        // latitude: '52.59057690',
        // longitude: '1.71665200'
    });

    const handleAddressChange = (field, value) => {
        setAddressData({
            ...addressData,
            [field]: value,
        });
    };

    // const getAddressReferenceIds = (accountNumber:any) => {
    //     return Array.from(new Set(accountsData
    //         .filter(item => item.account_number === accountNumber)
    //         .map(item => item.address_reference_id)));
    // };

    // const getProductReferenceIds = (addressReferenceId:any) => {
    //     return Array.from(new Set(accountsData
    //         .filter(item => item?.address_reference_id === addressReferenceId)
    //         .map(item => item?.product_reference_id)));
    // };

    const handleSubmit = async () => {
        if (selectedTerminals?.accountNumber !== selectedAccount) {
            setConfirmDialog(true)
        } else {
            createServiceLines()
        }
    };
//add checks
    const createServiceLines = async () => {
        setIsLoading(true);
        try {

            let params = {
                name: formData?.nickname,
                customPlanId: formData?.subscriptionType?.details && formData?.subscriptionType?.details?.length > 0 ? formData?.subscriptionType?.details[0]?.custom_plan_id : "",
                productReferenceId: formData?.subscriptionType?.plan_details && formData?.subscriptionType?.plan_details?.length > 0 ? formData?.subscriptionType?.plan_details[0]?.product_reference_id : "",
                accountNumber: selectedAccount,
                address: formData?.serviceAddress,
                organizationId: selectedOu,
                userTerminalId: formData?.terminalId,
                emailIds: emails

            }

            if(selectedOu){
                let res: any = await createServiceLine(params)
                if (res?.success) {
                        setIsLoading(false);
                        onClose();
                        clearData();
                        toast.success(`${formData?.nickname} Service Line created successfully. Please check after some time`, {
                            position: toast.POSITION.BOTTOM_LEFT,
                        })
    
                } else {
                    toast.error(`Unable to create Service Line - ${formData?.nickname} `, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    setIsLoading(false);
                    //  onClose();
                }
            }

        } finally {
            setIsLoading(false);
            // onClose();
            // clearData();
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => { clearData(); onClose(); }} maxWidth="sm" fullWidth>
                <DialogTitle>
                    ADD SERVICE LINE
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        Add a new service line. This will incur charges according to your account contract.
                    </Typography>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nickname (Optional)"
                                value={formData.nickname}
                                onChange={(e) => handleChange('nickname', e.target.value)}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Autocomplete
                                fullWidth
                                options={formattedSubscriptionDetails}
                                value={formattedSubscriptionDetails.find(option => option.product_reference_id === formData.subscriptionType) || null}
                                onChange={(event, newValue) => handleChange('subscriptionType', newValue?.product_reference_id || '')}
                                renderInput={(params) => <TextField {...params} label="Subscription Type" size="small" />}
                                getOptionLabel={(option) => option.label}
                            /> */}
                            <Autocomplete
                                fullWidth
                                disableClearable
                                options={formattedSubscriptionDetails}
                                value={formData?.subscriptionType}
                                onChange={(event, newValue: any) => {
                                    if (newValue?.name === "Custom Plans" || newValue?.name === "Standard Plans") {
                                        event.preventDefault(); 
                                        return; 
                                      }
                                    setEmails([])
                                    handleChange('subscriptionType', newValue || '');
                                    setSelectedAccount(newValue?.data && newValue?.data[0]?.account_number)
                                    if (newValue?.details) {
                                        setEmails([...newValue?.parent_pool?.users_to_notify, ...newValue?.users_to_notify])
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Subscription Type" size="small" />}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                    <React.Fragment key={option.label}>
                                      {option.name === "Custom Plans" || option.name === "Standard Plans" ? (
                                        <li
                                          {...props}
                                          style={{
                                            // fontWeight: 'bolder',
                                            pointerEvents: 'none',
                                            backgroundColor: '#f5f5f5', 
                                            padding: '8px 16px'
                                          }}
                                        >
                                          {option.label}
                                        </li>
                                      ) : (
                                        <li {...props}>{option.label}</li>
                                      )}
                                      {option.hasDivider && <Divider />}
                                    </React.Fragment>
                                  )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel style={{ marginTop: "-3px" }} id="demo-simple-select-label">Account Number</InputLabel>
                                <Select
                                    size="small"
                                    disabled={true}
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    label='Account Number'
                                    id="demo-simple-select"
                                    value={selectedAccount}
                                    onChange={(e) => { setSelectedAccount(e.target.value) }}
                                >
                                    {formData?.subscriptionType?.data?.map((d) => {
                                        return (
                                            <MenuItem key={d.account_number} value={d.account_number}>
                                                {d.account_number}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                            disableClearable
                                fullWidth
                                options={addressArray}
                                value={formData?.serviceAddress}
                                onChange={(event, newValue) => {
                                    if (newValue && newValue.id === 'new') {
                                        // handleAddNewAddress();
                                        setDialogOpen(true)
                                    } else {
                                        handleChange('serviceAddress', newValue || '');
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Address" size="small" />}
                                getOptionLabel={(option) => option.formatted_address}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id || option.formatted_address}>
                                        {option.formatted_address}
                                    </li>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Autocomplete
                                fullWidth
                                multiple
                                options={filteredTerminalArray}
                                getOptionLabel={(option) => `${option?.userTerminal} - ${option?.kitSerialNumber} - ${option?.dishSerialNumber}`}
                                value={filteredTerminalArray?.filter(option => formData?.terminalId?.includes(option?.userTerminal)) || []}
                                onChange={(event, newValue) => {
                                    setSelectedTerminals(newValue)
                                    handleChange('terminalId', newValue.map(item => item?.userTerminal));
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                            style={{ marginRight: 8 }}
                                        />
                                        {`${option?.userTerminal} - ${option?.kitSerialNumber} - ${option?.dishSerialNumber}`}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Starlink ID, Starlink Serial Number, or Kit Serial Number"
                                        size="small"
                                    />
                                )}
                            /> */}
                            <Autocomplete
                            disableClearable
                                fullWidth
                                options={filteredTerminalArray}
                                getOptionLabel={(option) => `${option?.userTerminal} - ${option?.kitSerialNumber} - ${option?.dishSerialNumber}`}
                                value={filteredTerminalArray?.find(option => option?.userTerminal === formData?.terminalId) || null}
                                onChange={(event, newValue) => {
                                    setSelectedTerminals(newValue)
                                    handleChange('terminalId', newValue ? newValue?.userTerminal : null)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Starlink ID, Starlink Serial Number, or Kit Serial Number"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: '8px',
                                        borderRadius: '10px',
                                        border: '1px solid #c2d3eb',
                                        boxShadow: 'none',
                                    }}
                                >
                                    {emails.map((email: any, index) => (
                                        <Chip
                                            key={index}
                                            label={email?.email}
                                            onDelete={() => handleDeleteEmail(email)}
                                            sx={{ margin: '4px' }}
                                        />
                                    ))}
                                    <TextField
                                        size="small"
                                        fullWidth
                                        variant="standard"
                                        error={!!error}
                                        placeholder="Press Enter to add email"
                                        helperText={error}
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        onKeyDown={handleAddEmail}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                padding: '0 4px',
                                            },
                                        }}
                                        sx={{
                                            width: 'auto',
                                            flexGrow: 1,
                                            minWidth: '120px',
                                            '& .MuiInputBase-root': {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '8px 4px',
                                                flex: 1,
                                            },
                                        }}
                                    />
                                </Paper>
                            </Box>
                            <Typography variant="caption" color="textSecondary" sx={{ marginTop: '4px', marginLeft: '8px' }}>
                                Quota Kontrol Notifications shall be sent to this email.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            <Button
                                variant="outlined"
                                style={{ marginRight: '10px' }}
                                onClick={() => { clearData(); onClose(); }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                disabled={formData?.terminalId === '' || formData?.serviceAddress === null || formData?.subscriptionType === null
                                    || formData?.nickname === '' || selectedAccount === '' || emails.length === 0}
                                variant="outlined"
                                onClick={handleSubmit}
                            >
                                {isLoading ? <CircularProgress size={24} /> : "ADD SERVICE LINE"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog PaperProps={{
                sx: {
                    width: '500px',
                    maxWidth: '500px'
                }
            }} open={confirmDialog} onClose={() => { setConfirmDialog(false) }}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography> Following Terminals will be moved from {selectedTerminals?.accountNumber} to {selectedAccount}: </Typography>

                    {selectedTerminals.kitSerialNumber && selectedTerminals.userTerminal ? 
                    <ListItem key={selectedTerminals.userTerminal}>
                        <ListItemText primary={selectedTerminals.kitSerialNumber} secondary={`Starlink Id: ${selectedTerminals.userTerminal}`} />
                    </ListItem> : <></> }
                    
                    <ListItem key={selectedTerminals.userTerminal}>
                        <ListItemText primary={"Plan"} secondary={` ${formData?.subscriptionType?.name || formData?.subscriptionType?.plan_name || ""}`} />
                    </ListItem>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { createServiceLines(); setConfirmDialog(false) }}
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                    >
                        {'Add'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog PaperProps={{
                sx: {
                    width: '500px',
                    maxWidth: '500px'
                }
            }} open={dialogOpen} onClose={() => { setDialogOpen(false) }}>
                <DialogTitle>Enter Address</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Formatted Address</Typography>
                            <TextField
                                fullWidth
                                placeholder="ex: 1 Rocket Road"
                                value={addressData.formattedAddress}
                                onChange={(e) => handleAddressChange('formattedAddress', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Address Lines</Typography>
                            <TextField
                                fullWidth
                                placeholder="Address lines"
                                value={addressData.addressLines}
                                onChange={(e) => handleAddressChange('addressLines', [e.target.value])}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Region Code</Typography>
                            <TextField
                                fullWidth
                                placeholder="Region ex: US"
                                value={addressData.regionCode}
                                onChange={(e) => handleAddressChange('regionCode', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Administrative Area Code</Typography>
                            <TextField
                                fullWidth
                                placeholder="State ex: CA"
                                value={addressData.administrativeAreaCode}
                                onChange={(e) => handleAddressChange('administrativeAreaCode', e.target.value)}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Latitude </Typography>
                            <TextField
                                fullWidth
                                placeholder="Latitude"
                                value={addressData.latitude}
                                onChange={(e) => handleAddressChange('latitude', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Longitude </Typography>
                            <TextField
                                fullWidth
                                placeholder="Longitude"
                                value={addressData.longitude}
                                onChange={(e) => handleAddressChange('longitude', e.target.value)}
                            />

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaved}
                        disabled={ addressData.formattedAddress === '' ||    addressData?.administrativeAreaCode === ``||
                            addressData.regionCode== ''||
                            addressData.latitude === ''||
                            addressData.longitude ===''}
                        variant="contained"
                        color="primary"
                    >
                        { 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkAdmin: state.starlinkAdmin,
    starlinkCloud: state.starlinkCloud,
});

export default withRouter(
    connect(mapStateToProps, {
        createSubOrg,
        createServiceLine
    })(CreateServiceLineDialog)
);