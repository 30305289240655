import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI } from "../../utils/util";
import { SIDE_MENU_ITEM_TRENDS } from "../../utils/constants";
import { BC_DASHBOARD_VESSELS, BC_DASHBOARD_ALERTS, BC_DASHBOARD_LOCATIONS } from "../../constants/Constants";
import { INTERVALS } from "../../constants/Constants";
// import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters"
import FleetSelection from "../../components/FleetSelection";
import NotAuthorized from "../NotAuthorized"
import SummaryCardDetails from "../../components/CardDetails/SummaryCardDetails";
import { updatePermission } from "../../actions/Users/authenticate";
import "./FleetDashboard.css";
import _ from "lodash";
import { clearSiteDashboard } from "../../actions/Users/authenticateDashboard";
import Tabs from "../../components/Tabs";
import UsageDashboardTable from "../../components/SummaryNewDashBoard/usageTable";
import ActivityTopUserTable from "../../components/SummaryNewDashBoard/ActivityTopUsers/activityTopUser";
import ChartsComponent from "../../components/SummaryNewDashBoard/ChartsComponent";
import UpTimeTable from "../../components/SummaryNewDashBoard/uptimeTable";
import UpTimeCharts from "../../components/SummaryNewDashBoard/UpTimeCharts";
import AlertCharts from "../../components/SummaryNewDashBoard/AlertCharts";
import AlertsTable from "../../components/SummaryNewDashBoard/alertsTable";
import TabsComponent from "../../components/NewTabs";
// const FILTER_BY_LIST = [
//   { label: "Vessel Name", value: "vesselName" },
//   { label: "System Type", value: "systemType" },
// ];

const FleetSummary = (props) => {
  const { authReducer, alertsDashboard, match, location, updatePermission, clearSiteDashboard2, history } = props;

  const _q = getDecodeURI(location?.search);
  const setBreadcrumbInfo: any = React.useRef();
  const [search, setSearch] = useState<string>("");
  // const [filterBy, setFilterBy] = useState<string>("");
  const [interval, setInterval] = useState(INTERVALS[0]);
  const [loading, setLoading] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [timePeriod, setTimePeriod] = useState({});
  const [tabValue, setTabValue] = useState(_q.hasOwnProperty('tab') && _q.tab == 'availability' ? 1 : _q.tab == 'alerts' ? 2 : 0);
  const [alertStatus, setAlertStatus] = useState({
    isCurrentAlert: true,
    Alerting: true,
    Normal: true
  });
  const [alertsPage, setAlertsPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);

  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];
  let breadcrumbInfo = BC_DASHBOARD_VESSELS;

  const [activeTab, setActiveTab] = useState<string>(_q.hasOwnProperty('tab') ? _q.tab : 'usage');

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_TRENDS !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_TRENDS };
  }

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    return () => {
      clearSiteDashboard();
    }
  }, []);

  useEffect(() => {
    setLoading(  authReducer.setDashboardPieUsageperWantypeLoading ||
      authReducer.setDashboardHistogramUsagePerWantypeLoading || authReducer.setDashboardUsagePieTop10AppsLoading || 
      authReducer.setDashboardPieWanUsageBySiteLoading || authReducer.setDashboardUsageHistogramUpDownLinkLoading ||
      authReducer.setDashboardUptimeSiteStatisticsTableLoading || authReducer.setDashboardUsageHistogramBySiteLoading ||
      authReducer.setDashboardUptimeLinechartByWanLoading || authReducer.setDashboardUptimePiechartBySiteLoading ||
      authReducer.setDashboardUptimeLinechartBySiteLoading || authReducer.setDashboardUptimePiechartByWanLoading || 
      authReducer?.setDashboardHistogramUsagePerSiteLoading || authReducer?.activityReportsTopUsersLoading || authReducer?.vesselDashboardLoading ||
      authReducer.newSummaryAlertPieChartByNameLoading || authReducer.newSummaryAlertPieChartBySiteLoading ||
      authReducer.newSummaryAlertLineChartByNameLoading || authReducer.newSummaryAlertLineChartBySiteLoading ||
      authReducer.newSummaryAlertTopWidgetLoading || authReducer.loading || authReducer.newSummaryUsageTableLoading ||
      authReducer.newSummaryUptimeAllLinksLoading || alertsDashboard.alertsMainInfoLoading
    );
  }, [ authReducer.setDashboardPieUsageperWantypeLoading,
  authReducer.setDashboardHistogramUsagePerWantypeLoading, authReducer.setDashboardUsagePieTop10AppsLoading,
  authReducer.setDashboardPieWanUsageBySiteLoading, authReducer.setDashboardUsageHistogramUpDownLinkLoading,
  authReducer.setDashboardUptimeSiteStatisticsTableLoading, authReducer.setDashboardUsageHistogramBySiteLoading,
  authReducer.setDashboardUptimeLinechartByWanLoading, authReducer.setDashboardUptimePiechartBySiteLoading,
  authReducer.setDashboardUptimeLinechartBySiteLoading, authReducer.setDashboardUptimePiechartByWanLoading, 
  authReducer?.setDashboardHistogramUsagePerSiteLoading,  authReducer?.activityReportsTopUsersLoading, authReducer?.vesselDashboardLoading,
  authReducer.newSummaryAlertPieChartByNameLoading, authReducer.newSummaryAlertPieChartBySiteLoading,
  authReducer.newSummaryAlertLineChartByNameLoading, authReducer.newSummaryAlertLineChartBySiteLoading,
  authReducer.newSummaryAlertTopWidgetLoading, authReducer.loading, authReducer.newSummaryUsageTableLoading,
  authReducer.newSummaryUptimeAllLinksLoading, alertsDashboard.alertsMainInfoLoading
]);

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
            return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
        vessels = filteredVessels?.filter((el) => el?.id !== "");
    } else {
        vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer.filteredVessels])

//callback for Tabs Component returning value of current tab
  const handleTabValue = (value) =>{
    setTabValue(value);
    setAlertStatus({
      isCurrentAlert: true,
      Alerting: true,
      Normal: true
    })
    let params: any = getDecodeURI(location?.search);
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    delete params.search;
    value == 0 ? params.tab = 0 : value == 1 ? params.tab = 1 : params.tab = 2;
    doNavigate(params);
  }

  const doNavigateTab = (pathname, tabName) => {
    const currentParams = getDecodeURI(location?.search);
    const params:any = {};
    if (currentParams.ouName) {
      params['ouName'] = currentParams.ouName;
    }
    if (currentParams.ouId) {
      params['ouId'] = currentParams.ouId;
    }
    if (currentParams.k4Ids) {
      params['k4Ids'] = currentParams.k4Ids;
    }
    if(currentParams.sideNav){
      params['sideNav'] = currentParams.sideNav
    }
    if(currentParams.interval) {
      params['interval'] = currentParams.interval
    }
    if(currentParams.startDate && currentParams.startDate > 1) {
      params['startDate'] = currentParams.startDate
    }
    if(currentParams.endDate && currentParams.endDate > 1) {
      params['endDate'] = currentParams.endDate
    }

    params.tab = tabName;
    history.push({ pathname, search: getEncodedURI(params) });
    setActiveTab(tabName);
    setAlertStatus({
      isCurrentAlert: true,
      Alerting: true,
      Normal: true
    })
    setTabValue(tabName == 'usage' ? 0 : tabName == 'availability' ? 1 : 2)
  }

  const handleSiteClick = (row) => {
    let params: any = getDecodeURI(location?.search);
    row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
    delete params.page;
    delete params.limit;
    delete params.sort;
    delete params.sortOrder;
    delete params.search;
    doNavigate(params);
  }

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  const tabs = [
    { tabName: 'Usage', tabValue: 'usage', pathName: '/fleet-dashboard/newsummary/sites' },
    { tabName: 'Availability', tabValue: 'availability', pathName: '/fleet-dashboard/newsummary/sites' },
    { tabName: 'Alerts', tabValue: 'alerts', pathName: '/fleet-dashboard/newsummary/sites' }
  ]

  return (
    <div className="Form">
      <CommonFilters breadcrumbinfo={breadcrumbInfo} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading || authReducer?.userPreferencesLoading} setTimePeriod={setTimePeriod} handleSiteClick={handleSiteClick} userGuide={[true, 'trends']}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} alertStatus={alertStatus} setAlertStatus={setAlertStatus} trendsAlertsFilter={activeTab == 'alerts' ? true : false} />
      <Grid key="dashboard"  container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="site-dashboard-NewSummary">
          {
            isAuthorizedToViewPage ? [
              <Grid className="new-summary-dash-container">
                <Grid key="SubHeader" item container className="SubHeader_NewSummary padding-right-17">
                  <SummaryCardDetails search={search} interval={interval} vesselList={authReducer?.getVesselsListing} selectedOu={authReducer.selectedOu} tabValue={tabValue} alertStatus={alertStatus} />
                </Grid>
                <Grid className="summary-charts-comp">
                  {tabValue == 0 && <ChartsComponent timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {tabValue === 1 && <UpTimeCharts vessels={vessels} timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {tabValue === 2 && <AlertCharts handleSiteClick={handleSiteClick} alertStatus={alertStatus} setAlertsPage={setAlertsPage} />}
                </Grid>
                <Grid key="summary" className="summary padding-l-r">
                  {/* if single site activity top user table else usage dashboard table */}
                  { vessels && vessels.length == 1 && tabValue == 0 ? <ActivityTopUserTable /> : tabValue == 0 ? <UsageDashboardTable handleSiteClick={handleSiteClick} /> : null}
                  {tabValue == 1 && <UpTimeTable vessels={vessels} timePeriod={timePeriod} handleSiteClick={handleSiteClick} />}
                  {tabValue == 2 && <AlertsTable vessels={vessels} alertStatus={alertStatus} handleSiteClick={handleSiteClick} setAlertStatus={setAlertStatus} alertsPage={alertsPage} setAlertsPage={setAlertsPage} />}
                </Grid>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  alertsDashboard: state.alertsDashboard,
  errorReducer: state.errorReducer
});

export default withRouter(
  connect(mapStateToProps, { updatePermission, clearSiteDashboard })(FleetSummary)
);
