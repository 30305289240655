// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import {  GET_THREAT_DASHBOARD_TOPWIDGET, GET_TOP_10_THREAT_BY_NAME, SET_THREAT_DASHBOARD_TOPWIDGET_LOADING, SET_TOP_10_THREAT_BY_NAME_LOADING,GET_TOP_10_THREAT_BY_SEVERITY, SET_TOP_10_THREAT_BY_SERVERITY_LOADING, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY, GET_TOP_10_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING, GET_ALL_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING, SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING, GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL, GET_THREAT_SITE_LIST_LOADING, GET_THREAT_SITE_LIST, GET_THREAT_DEVICE_LIST, GET_THREAT_DEVICE_LIST_LOADING, GET_USER_THREAT_TABLE_DATA, SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING } from "../types";
import moment from "moment";
import { getAvilWindow } from "./authenticateDashboard";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

export const getThreatTopWidget = (vessels, startDate, endDate, severityType, alertStatus) => (dispatch) => {
    dispatch({ type: SET_THREAT_DASHBOARD_TOPWIDGET_LOADING, payload: true });
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    const threatWidget: any = {
        query: 'SNORT_DASHBOARD_SEVERITY_TOP_WIDGET',
        named: true,
        format: "csv",
        "parameters": {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
          },
    }
    api
        .postRequestOut(`/store/ch/query`, threatWidget)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DASHBOARD_TOPWIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getTop10ThreatByName = (vessels, startDate, endDate, limit,severityType ) => (dispatch) => {

    dispatch({ type: SET_TOP_10_THREAT_BY_NAME_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'SNORT_DASHBOARD_THREAT_TYPE_HORIZONTAL_BAR_CHART',
        named: true,
        parameters: {
          "start_time": startDate,
          "end_time": endDate,
          "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
          "name": "",
          "devicename": "",
          "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
          "limits":"",
          "class":""
        },
        "format": "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_10_THREAT_BY_NAME)
        })
        .catch((err) => handleError(err, dispatch));
};


export const getTop10ThreatBySeverity= (vessels, startDate, endDate, limit, severityType) => (dispatch) => {
    dispatch({ type: SET_TOP_10_THREAT_BY_SERVERITY_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: any = {
        query: 'SNORT_DASHBOARD_SEVERITY_PIE',
        named: true,
        parameters: {
          "start_time": startDate,
          "end_time": endDate,
          "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
          "name": "",
          "devicename": "",
          "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
          "limits":""
        },
        "format": "csv"
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_TOP_10_THREAT_BY_SEVERITY)
        })
        .catch((err) => handleError(err, dispatch));
};

export const getThreatHistogramBySeverity = (vessels, startDate, endDate, severityType, limit, selectedBin) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY_LOADING, payload: true });
    let data: any = {
        query: "SNORT_DASHBOARD_BAR_CHART",
        named: true,
        format:"chart",
        groupBy:"severity",
        series:"total_hits",
        tsColumn:"ts",
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": "",
            "devicename": "",
            "interval":"1 HOUR",
            "siteid":  vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
       
        }
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {
            handleResponse(res, dispatch, GET_THREAT_DASHBORAD_BAR_CHART_ON_SEVERITY)
        })
        .catch((err) => handleError(err, dispatch));
};


export const getTopThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_TOP_10_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const top10TheratTableData: any = {
        query: 'SNORT_DASHBOARD_TOP_THREAT_TABLE',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": page ? page : 0,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority,total_hits",
            "sort_order":sortOrder ? sortOrder : "desc",
            "class": ""
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, top10TheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_TOP_10_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};


export const getAllThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_ALL_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const allTheratTableData: any = {
        query: 'SNORT_DASHBOARD_THREAT_LOG',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": page ? page : 0,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority asc,Attack_Time",
            "sort_order":sortOrder ? sortOrder : "desc"
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_ALL_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};

export const getUserThreatTableData = (vessels, startDate, endDate, severityType, page, limit, sort, sortOrder, search) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: SET_THREAT_DASHBOARD_USER_THREAT_TABLE_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const userTheratTableData: any = {
        query: 'SNORT_DASHBOARD_USER_VERNABILITY_TABLE',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `${severityType.toUpperCase()}`:"",
            "name": "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "pageOffset": page ? page : 0,
            "pageSize": limit,
            "search":search ? search: "",
            "sort_column":sort ? sort : "priority asc,Attack_Time",
            "sort_order":sortOrder ? sortOrder : "desc"
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, userTheratTableData)
        .then((res) => handleResponse(res, dispatch, GET_USER_THREAT_TABLE_DATA))
        .catch((err) => handleError(err, dispatch));
};


export const getThreatTopWidgetTotal = (vessels, startDate, endDate, severityType, alertStatus) => (dispatch) => {
    dispatch({ type: SET_THREAT_DASHBOARD_TOPWIDGET_TOTAL_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const vesselMap: any = [];
    vessels?.map((item: any) => {
        let vesselName = item["id"];
        vesselMap.push(vesselName);
    })
    let k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
    const threatWidget: any = {
        query: 'SNORT_DASHBOARD_TOTAL_TOP_WIDGET',
        named: true,
        format: "csv",
        "parameters": {
            "start_time": startDate,
            "end_time": endDate,
            "severity": severityType && severityType !== "all "? `${severityType.toUpperCase()}`:"",
            "name": "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : ''
          },
    }
    api
        .postRequestOut(`/store/ch/query`, threatWidget)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DASHBOARD_TOPWIDGET_TOTAL))
        .catch((err) => handleError(err, dispatch));
};


export const getAllThreatSiteList = (vessels, startDate, endDate, severityType, search, threatName) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: GET_THREAT_SITE_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const allTheratSiteList: any = {
        query: 'SNORT_DASHBOARD_THREAT_TABLE_SITE_LIST',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName && threatName !=="" ? `and msg='${threatName}'` : "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "class": ""
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratSiteList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_SITE_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getAllThreatDeviceList = (vessels, startDate, endDate, severityType, search, threatName) => (dispatch) => {
    const avail_window = Math.ceil((Math.ceil(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3)) / 5);
    dispatch({ type: GET_THREAT_DEVICE_LIST_LOADING, payload: true });
    let isClickHouse = true
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const allTheratDeviceList: any = {
        query: 'SNORT_DASHBOARD_THREAT_TABLE_DEVICE_NAME_LIST',
        named: true,
        parameters: {
            "start_time": startDate,
            "end_time": endDate,
            "severity":  severityType && severityType !== "all "? `and severity='${severityType.toUpperCase()}'`:"",
            "name": threatName && threatName !=="" ? `and msg='${threatName}'` : "",
            "devicename": "",
            "siteid": vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            "search":search ? search: "",
            "class": ""
        },
        format: 'csv'
    }
    api
        .postRequestOut(`${apiUrl}`, allTheratDeviceList)
        .then((res) => handleResponse(res, dispatch, GET_THREAT_DEVICE_LIST))
        .catch((err) => handleError(err, dispatch));
};