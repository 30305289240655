export const CONFIG_EDGE_ADMINISTRATION_NAME = "edge-administration";

export const RESOURCE_FIELD_VESSELNAME = "vessel-name";
export const HUB_RESOURCE_FIELD_VESSELNAME = "site_name";
export const RESOURCE_FIELD_ID = "id";
export const RESOURCE_FIELD_TYPE = "type";
export const RESOURCE_TYPE_VESSEL_VALUE = "Vessel";


export const DEFAULT_TIMEZONE = "Asia/Calcutta";
export const DEFAULT_DATE_FORMAT = "MM/DD/yyyy";
// format Tue, 23 Mar 21, 19:04:59
export const DAYDDMMMYYHMMSS_DATE_FORMAT = "ddd, DD MMM YY, hh:mm:ss";
// format Tue, 23 Mar, 19:04:59
export const DAYDDMMMHMMSS_DATE_FORMAT = "ddd, DD MMM, hh:mm:ss";

export const MMDDYYYYHMMSS_DATE_FORMAT = "MM/DD/YYYY, hh:mm:ss A";

//24hrs date-time format 
export const MMDDYYYYHMMSS_DATE_FORMAT_24_HRS = "MM/DD/YYYY, HH:mm:ss";

//offset from UTC 
export const YYYYMMDDHHMM_UTC_OFFSET = "YYYY-MM-DD hh:mm:ss.SSS +-HHmm"

export const YYYYMMDD_HHmmss = "YYYY-MM-DD HH:mm:ss"

export const SIDE_MENU_ITEM_DASHBOARD = "dashboard"
export const SIDE_MENU_ITEM_TRENDS = "trends"
export const SIDE_MENU_ITEM_SERVICE_MANAGEMENT = "servicemanagement";
export const SIDE_MENU_ITEM_SIM_REPORTS = "simreports";
export const SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT = "accountmanagement";
export const SIDE_MENU_ITEM_ALERTS = "alerts";
export const SIDE_MENU_ITEM_EDGEADMIN = "edgeadmin";
export const SIDE_MENU_ITEM_MAP = "map";
export const SIDE_MENU_ITEM_REPORTS = "reports"
export const SIDE_MENU_ITEM_TICKETS = "tickets";
export const SIDE_MENU_ITEM_SOFTWARE_LISTING = "softwareListing";
export const SIDE_MENU_ITEM_INVENTORY = "inventory"
export const SIDE_MENU_ITEM_AUDIT_LOGS = "auditLogs"
export const SIDE_MENU_ITEM_QUOTA_MANAGEMENT = "quotaManagement"
export const SIDE_MENU_ITEM_STARLINK = "starlink"
export const SIDE_MENU_ITEM_KNOWLEDGE_CENTER = "knowledgeCenter"
export const SIDE_MENU_ITEM_HUB = "hub"
export const SIDE_MENU_ITEM_CYBER = "cyber"

export const LOCATION_ID_KEY = "locationId";
export const ID_KEY = "id";
export const NAME_KEY = "name";
export const TYPE_KEY = "type";
export const HARDWARE_VESION_KEY = "hardware_version";
export const SOFTWARE_VERSION_KEY = "software_version";
export const STATUS = "status";

export const SITE_NOTES = "siteNotes"
export const EDGE_EVENTS = "events"
export const CHARTS = "SDWAN"
export const HA = "highAvailability"
export const REMOTE_CONNECTIONS = "remote-connections"
export const QUOTA_MANAGEMENT = "quota-management"
export const KNOWLEDGE_CENTER = "knowledge-center"
export const USER_DATA = "activity"
export const SIM_ANALYSIS = "cellular"
export const DATA_USAGE = "dataUsage"
export const GRAPHICAL_DATA_USAGE = "wanUsage"
export const FIREWALL_REPORT = "firewall-report"
export const ACCOUNT_MANAGEMENT = 'accounts'
export const LOGIN_HISTORY = 'account-activity'
export const GROUP_SETTINGS = 'groupSettings'
export const GENERATED_REPORTS = 'generatedReports'
export const CONTENT_FILTER = 'contentFilter'
export const STARLINK_UNMANAGED = 'starlink-cloud'
export const SYSTEM_HEALTH = 'system_health'
export const HUB = 'hub'
export const THREAT_DASHBOARD= 'threatDashboard'
export const CYBER = 'cyber'

// Tickets more filter
export const NO_TIMEFRAME = "No timeframe";
export const VIEW_LAST_WEEK = "View last week";
export const VIEW_LAST_MONTH = "View last month";
export const CUSTOM_DATES = "Custom Dates";

    