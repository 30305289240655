import { useEffect, useState } from "react";
import "./NewSideMenu.css"
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SidebarNavCategoryIcon from "../../asset/image/SideNavCategoryIcon.svg";
import AdvancedIcon from "../../asset/image/advanced.svg" 
import StandardIcon from "../../asset/image/standard.svg" 
import SidebarNavNotificationIcon from "../../asset/image/SideNavNotificationIcon.svg";
import SidebarNavMapIcon from "../../asset/image/SideNavMapIcon.svg";
import SidebarNavNotesIcon from "../../asset/image/SideNavNotesIcon.svg";
import SidebarNavUserIcon from "../../asset/image/SideNavUserIcon.svg";
import SidebarNavTicketsIcon from "../../asset/image/SideNavTicketsIcon.svg";
import SidebarNavDescription from "../../asset/image/SideNavDescription.svg";
import SideNavSwConfigIcon from "../../asset/image/SideNavSwConfigIcon.svg";

import SideNavKnowledgeCenter from "../../asset/image/SideNavKnowledgeCenter.svg";
import SideNavCaptive from "../../asset/image/SideNavCaptive.svg";
import SideNavStarlink from "../../asset/image/SideNavStarlink.svg";
import SideNavQuotaControl from "../../asset/image/SideNavQuotaControl.svg";
import SideNavInventory from "../../asset/image/SideNavInventory.svg";
import { CAPTIVE_ACCESS_PORTAL_URL, SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import {
    SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT, SIDE_MENU_ITEM_ALERTS,
    SIDE_MENU_ITEM_DASHBOARD, SIDE_MENU_ITEM_MAP,
    SIDE_MENU_ITEM_TICKETS, SIDE_MENU_ITEM_SOFTWARE_LISTING, SIDE_MENU_ITEM_SIM_REPORTS,
    SIDE_MENU_ITEM_AUDIT_LOGS,SIDE_MENU_ITEM_INVENTORY,
    SIDE_MENU_ITEM_KNOWLEDGE_CENTER,QUOTA_MANAGEMENT,
    KNOWLEDGE_CENTER, SIDE_MENU_ITEM_STARLINK, SIDE_MENU_ITEM_QUOTA_MANAGEMENT, SIDE_MENU_ITEM_TRENDS, SIDE_MENU_ITEM_HUB, HUB, SIDE_MENU_ITEM_CYBER, CYBER
} from "../../utils/constants";
import { CAPTIVE_ACCESS_ENABLED } from "../../constants/Constants";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, removeQueryParamK4Ids, getDataFromLocalStorageParsed } from "../../utils/util";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GET_DRAWER_OPEN, UPDATE_NEW_SUMMARY_END_DATE, UPDATE_NEW_SUMMARY_START_DATE } from "../../actions/types";
import moment from "moment";
import HubIcon from '@mui/icons-material/Hub';
import SecurityIcon from '@mui/icons-material/Security';

const drawerWidth = 212;

const _allDisabledItems = {
    [SIDE_MENU_ITEM_DASHBOARD]: false,
    [SIDE_MENU_ITEM_TRENDS]: false,
    [SIDE_MENU_ITEM_ALERTS]: false,
    [SIDE_MENU_ITEM_SIM_REPORTS]: false,
    [SIDE_MENU_ITEM_MAP]: false,
    [SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT]: false,
    [SIDE_MENU_ITEM_TICKETS]: false,
    [SIDE_MENU_ITEM_SOFTWARE_LISTING]: false,
    [SIDE_MENU_ITEM_INVENTORY]: false,
    [SIDE_MENU_ITEM_AUDIT_LOGS]: false,
    [SIDE_MENU_ITEM_QUOTA_MANAGEMENT]: false,
    [SIDE_MENU_ITEM_KNOWLEDGE_CENTER]: false,
    [SIDE_MENU_ITEM_STARLINK]: false,
    [CAPTIVE_ACCESS_PORTAL_URL]: false,
    [SIDE_MENU_ITEM_HUB]: false,
    [SIDE_MENU_ITEM_CYBER]: false,
}

const _allEnabledItems = {
    [SIDE_MENU_ITEM_DASHBOARD]: true,
    [SIDE_MENU_ITEM_TRENDS]: true,
    [SIDE_MENU_ITEM_ALERTS]: true,
    [SIDE_MENU_ITEM_SIM_REPORTS]: true,
    [SIDE_MENU_ITEM_MAP]: true,
    [SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT]: true,
    [SIDE_MENU_ITEM_TICKETS]: true,
    [SIDE_MENU_ITEM_SOFTWARE_LISTING]: true,
    [SIDE_MENU_ITEM_INVENTORY]: true,
    [SIDE_MENU_ITEM_AUDIT_LOGS]: true,
    [SIDE_MENU_ITEM_QUOTA_MANAGEMENT]: true,
    [SIDE_MENU_ITEM_KNOWLEDGE_CENTER]: true,
    [SIDE_MENU_ITEM_STARLINK]: true,
    [CAPTIVE_ACCESS_PORTAL_URL]: true,
    [SIDE_MENU_ITEM_HUB]: true,
    [SIDE_MENU_ITEM_CYBER]: true
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(11)} + 1px) !important`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NewSideMenu = (props: any) => {
    const { authReducer, location, history, children, isReadOnlyUser } = props;
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleDrawerOpen = () => {
        dispatch({ type: GET_DRAWER_OPEN, payload: true });
        let params = getDecodeURI(location?.search);
        params.sideNav = true;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleDrawerClose = () => {
        dispatch({ type: GET_DRAWER_OPEN, payload: false });
        let params = getDecodeURI(location?.search);
        params.sideNav = false;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const onlyStarlinkUser = getDataFromLocalStorageParsed("loggedInUser")?.data?.user?.starlinkOnlyUser;
    const service = JSON.parse(localStorage.getItem('permissions') || '');
    const [isStarLinkEnabled, setIsStarlinkEnabled] = useState(false);
    if(service && !isStarLinkEnabled) {
        service?.map((list)=>{
            if(list.service == 'starlink'){
                localStorage.setItem("isStarlinkEnabled", "true")
                setIsStarlinkEnabled(true);
            }
          })
    }
  

    const [selectedMenuItem, setSelectedMenuItem] = useState(authReducer.sideMenu.menuItem);
    const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
    const [enabledSideMenuItem, setEnabledSideMenuItem] = useState<any>(_allEnabledItems);

    const [openWindowPopup, setWindowPopup] = useState<boolean>(true)

    const appendEdgeToken = (url) => {
        const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
        let edgeToken = loggedInUser?.data?.edge_token;
        if (!_.isEmpty(edgeToken)) {
            url = `${url}?token=${edgeToken}`
        }
        return url
    }

    const menuItems = [
        {
            key: "dashboard",
            label: "Sites",
            icon: SidebarNavCategoryIcon,
            value: SIDE_MENU_ITEM_DASHBOARD,
            path: "/fleet-dashboard/sites",
        },
        {
            key: "trends",
            label: "Trends",
            icon: StandardIcon,
            value: SIDE_MENU_ITEM_TRENDS,
            path: "/fleet-dashboard/newsummary/sites",
        },
        ...(localStorage.getItem("isBetaUser") === "true") ? [{
            key: 'cyber',
            label: 'Cyber *',
            icon: SideNavInventory,
            value: SIDE_MENU_ITEM_CYBER,
            path: `/${CYBER}`,
        }] : [],
        // {
        //     key: "advanced",
        //     label: "Advanced",
        //     icon: AdvancedIcon,
        //     value: SIDE_MENU_ITEM_DASHBOARD,
        //     path: "/fleet-dashboard/sites",
        // },
        {
            key: "map",
            label: "Map",
            icon: SidebarNavMapIcon,
            value: SIDE_MENU_ITEM_MAP,
            path: "/vessel-map",
        },
        {
            key: "reports",
            label: "Reports",
            icon: SidebarNavNotesIcon,
            value: SIDE_MENU_ITEM_SIM_REPORTS,
            path: "/reports/wanUsage",
        },
        ...(localStorage.getItem("isAccountsEnabled") === "true" && !isReadOnlyUser ? [{
            key: "accountManagement",
            label: "Account Management",
            icon: SidebarNavUserIcon,
            value: SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT,
            path: "/account-management/accounts",
        }] : []),
        {
            key: "alerts",
            label: "Alerts",
            icon: SidebarNavNotificationIcon,
            value: SIDE_MENU_ITEM_ALERTS,
            path: "/alerts/alerts",
        },
        ...(localStorage.getItem("isBetaUser") === "true") && !isReadOnlyUser
        ? [
            {
                key: "auditLogs",
                label: "Audit Logs *",
                icon: SidebarNavDescription,
                value: SIDE_MENU_ITEM_AUDIT_LOGS,
                path: "/audit-logs",
            },  
            ]
        : [],
        ...(!isReadOnlyUser ?
        [
        {
            key: "tickets",
            label: "Tickets",
            icon: SidebarNavTicketsIcon,
            value: SIDE_MENU_ITEM_TICKETS,
            path: "/tickets",
        }]: 
        []),
        ...(!isReadOnlyUser ?
        [
        {
            key: "softwareListing",
            label: "S/W Configuration",
            icon: SideNavSwConfigIcon,
            value: SIDE_MENU_ITEM_SOFTWARE_LISTING,
            path: "/softwareList",
        }]:[]),
        {
            key: "inventory",
            label: "Inventory",
            icon: SideNavInventory,
            value: SIDE_MENU_ITEM_INVENTORY,
            path: "/inventory",
        },
        ...(localStorage.getItem("isBetaUser") === "true") ? [{
            key: 'Hub',
            label: 'Hub *',
            icon: SideNavInventory,
            value: SIDE_MENU_ITEM_HUB,
            path: `/${HUB}`,
        }] : [],
        {
            key: `${window?.location?.host.includes("pulsar") ? "Quota Control" : "Quota Kontrol"}`,
            label: `${window?.location?.host.includes("pulsar") ? "Quota Control" : "Quota Kontrol"}`,
            icon: SideNavQuotaControl,
            value: SIDE_MENU_ITEM_QUOTA_MANAGEMENT,
            path: `/${QUOTA_MANAGEMENT}`,
        },
        ...((onlyStarlinkUser || isStarLinkEnabled)  ?
            [
            {
                key: "starlink",
                label: "Starlink",
                icon: SideNavStarlink,
                value: SIDE_MENU_ITEM_STARLINK,
                path: "/starlink/dashboard",
            }]: 
            []),
        ...(localStorage.getItem(CAPTIVE_ACCESS_ENABLED) === "true" &&  !isReadOnlyUser
        ? [
            {
                key: "captive",
                label: "Captive Admin",
                icon: SideNavCaptive,
                value: CAPTIVE_ACCESS_PORTAL_URL,
                path: "/",
            },  
            ]
        : []),
        // {
        //     key: "knowledgeCenter",
        //     label: "Knowledge Center",
        //     icon: SideNavKnowledgeCenter,
        //     value: SIDE_MENU_ITEM_KNOWLEDGE_CENTER,
        //     path: `/${KNOWLEDGE_CENTER}`,
        // }
    ];

    useEffect(() => {
        // check if only starlink user
        const onlyStarlinkUser = getDataFromLocalStorageParsed("loggedInUser")?.data?.user?.starlinkOnlyUser;
        if(onlyStarlinkUser){
          switchMenu(SIDE_MENU_ITEM_STARLINK, "/starlink/dashboard")
          setEnabledSideMenuItem({..._allDisabledItems, [SIDE_MENU_ITEM_STARLINK]: onlyStarlinkUser})
        }

        const onlyCaptiveUser = getDataFromLocalStorageParsed("loggedInUser")?.data?.user?.canOnlyUser;
        if(onlyCaptiveUser){
          switchMenu(CAPTIVE_ACCESS_PORTAL_URL, "/")
          setEnabledSideMenuItem({..._allDisabledItems, [CAPTIVE_ACCESS_PORTAL_URL]: onlyCaptiveUser})
          window.location.href = appendEdgeToken(CAPTIVE_ACCESS_PORTAL_URL)
        }
      }, [])

    useEffect(() => {
        setSelectedMenuItem(authReducer.sideMenu.menuItem);
        const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
        const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
        const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
        setIsAuthorizedToViewBetaFeature(authorizedBetaUser)
    }, [authReducer.sideMenu]);

    const ACTION_TYPES = {
        GET_TOS_ACCEPTED: "get_tos_accepted",
        SET_LOADING: "starlinkAdmin/setLoading",
        ERROR_VALIDATION:"error_in_validation"
    }

    function switchMenu(name: string, url: string) {
        authReducer.sideMenu = { menuItem: name };
        let q: any = getDecodeURI(location?.search);
        let params: any = {};
        if (q.hasOwnProperty("ouName") && q.hasOwnProperty("ouId") &&
            '' != q.ouName && '' != q.ouId) {
            params.ouName = q.ouName;
            params.ouId = q.ouId;
        }
        if(name == 'alerts') {
            params.status = 'isCurrentAlert'
        }
        if (q.hasOwnProperty("k4Ids") && '' != q.k4Ids) {
            params.k4Ids = q.k4Ids;
        }
        if (name === SIDE_MENU_ITEM_TICKETS) {
            params.status = "Open";
        }
        // dashboard redirection with internet status filter, when all sites are selected
        if (name === SIDE_MENU_ITEM_DASHBOARD && !q.hasOwnProperty("k4Ids")) {
            params.filterApplied = "true";
            params.internetStatus = "Online,Offline";
        }

        if(q.hasOwnProperty("sideNav")){
            params.sideNav = q.sideNav
        }
        if(q.hasOwnProperty("interval")) {
            params.interval = q.interval
        }
        if(q.hasOwnProperty("startDate") && q.startDate > 1) {
            params.startDate = q.startDate
        }
        if(q.hasOwnProperty("endDate") && q.endDate > 1) {
            params.endDate = q.endDate
        }

        if(q.hasOwnProperty("serviceLineAccess") ) {
            params.serviceLineAccess = q.serviceLineAccess
        }else{
            params.serviceLineAccess = "All" 
        }

        if(name == 'starlink' && name != selectedMenuItem) {
            params.starlinkTab = true;
            params.interval = "1d";
            params.startDate = moment(Date.now()).subtract(1, 'day').valueOf()
            params.endDate = moment(Date.now()).valueOf()
            dispatch({ type: ACTION_TYPES.GET_TOS_ACCEPTED, payload: false });
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { validateTosLoading: false } });
            dispatch({ type: ACTION_TYPES.ERROR_VALIDATION, payload: { errorInTosValidation: false } });
        }
        removeQueryParamK4Ids()
        history.push({ pathname: url, search: `?${getEncodedURI(params)}` });
    }

    return (
        // css to class
        <Box className="SideNavDrawer menuScroll" sx={{ display: 'flex' }}>
            {!authReducer?.getDrawerOpen ?
                <IconButton onClick={handleDrawerOpen} className="drawerOpenIcon" style={{ left: '90px', top: '69px' }}>
                    <ChevronRightIcon />
                </IconButton>
                :
                <IconButton onClick={handleDrawerClose} className="drawerOpenIcon" style={{ left: '212px', top: '69px' }} >
                    <ChevronLeftIcon />
                </IconButton>
            }
            <Drawer variant="permanent" open={authReducer?.getDrawerOpen} >
                <div className="drawerTopBorder">
                </div>
                <List className="sidenavPaddingTop">
                    <div className="iconDivNewSideNav">
                    {menuItems.map((item) => (
                        <ListItem
                            key={item?.key}
                            disablePadding
                            sx={{display: 'block', fontSize: item?.value === selectedMenuItem ? "14 Px Medium" : "14 Px Semibold", color: item?.value === selectedMenuItem ? "#0E0548" : "#6E7079", 
                                background: item?.value === selectedMenuItem ? "#F4F5FA" : "#ffffff",position: item?.key === "knowledgeCenter" ? "absolute" : "", bottom:  item?.key === "knowledgeCenter" ? "-100px" : "", }}
                        >
                            <ListItemButton
                                disabled={!enabledSideMenuItem[item?.value]}
                                className="listItemSideMenu navListItems"
                                sx={{ minHeight: 38,justifyContent: authReducer?.getDrawerOpen ? 'initial' : 'center',px: 2.5, }}
                                onClick={() => {item?.key === "captive"? window.open(appendEdgeToken(CAPTIVE_ACCESS_PORTAL_URL)) : switchMenu(item?.value, item?.path)}}
                                title={item?.label}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: authReducer?.getDrawerOpen ? 3 : 'auto', justifyContent: 'center', }}
                                >
                                {item?.key === 'Hub' ? (
                                    <HubIcon className={`Hub-new-menu-icon navIconMargin ${item?.value === selectedMenuItem ? "iconColor-select" : "iconColor-unselect"}`} />
                                ) : item?.key === 'cyber' ? (
                                    <SecurityIcon className={`Hub-new-menu-icon navIconMargin ${item?.value === selectedMenuItem ? "iconColor-select" : "iconColor-unselect"}`}/>
                                ) : (
                                    <img
                                        src={item?.icon}
                                        className={`SideNavImg navIconMargin ${item?.value === selectedMenuItem ? "iconColor-select" : "iconColor-unselect"}`}
                                        alt={item?.label}
                                    />
                                )}
                                </ListItemIcon>
                                {authReducer?.getDrawerOpen ? (
                                    <ListItemText
                                        primary={item?.label}
                                        className="sideNavText"
                                        primaryTypographyProps={{ style: { fontWeight: item?.value === selectedMenuItem ? 600 : 500,  }, }}
                                    />
                                ) : null}
                            </ListItemButton>
                        </ListItem>
                    ))}
                    </div>
                    <div style={{ flex: 1 }}></div>
           

<ListItem
                            key={"knowledgeCenter"}
                            disablePadding
                            sx={{display: 'block', background: SIDE_MENU_ITEM_KNOWLEDGE_CENTER === selectedMenuItem ? "#F4F5FA" : "#ffffff",position: "absolute" , bottom:  "0" }}
                        >
                            <ListItemButton
                                className="knowledgeCenterPadding"
                                disabled={!enabledSideMenuItem[SIDE_MENU_ITEM_KNOWLEDGE_CENTER]}
                                sx={{ minHeight: 48,justifyContent: authReducer?.getDrawerOpen ? 'initial' : 'center',px: 2.5, }}
                                onClick={() => { switchMenu(SIDE_MENU_ITEM_KNOWLEDGE_CENTER, `/${KNOWLEDGE_CENTER}`)}}
                                title={"Knowledge Base"}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: 0, mr: authReducer?.getDrawerOpen ? 3 : 'auto', justifyContent: 'center', }}
                                >
                                    <img src={SideNavKnowledgeCenter} className="SideNavImg navIconMargin" alt={"Knowledge Base"} />
                                </ListItemIcon>
                                {authReducer?.getDrawerOpen ? (
                                    <ListItemText
                                        primary={"Knowledge Base"}
                                        className="sideNavText"
                                        primaryTypographyProps={{ style: { fontWeight: SIDE_MENU_ITEM_KNOWLEDGE_CENTER === selectedMenuItem ? 600 : 500,  }, }}
                                    />
                                ) : null}
                            </ListItemButton>
                        </ListItem>
                </List>
            </Drawer>
            <Box component="main" style={authReducer?.getDrawerOpen ? {width:'calc(100vw - 215px)'} : {width:'calc(100vw - 88px)'}}>
                {/* <DrawerHeader /> */}
                {children}
            </Box>
        </Box>);
}

NewSideMenu.propTypes = {
    window: PropTypes.func,
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    isReadOnlyUser: state?.authReducer?.isReadOnlyUser
});

export default withRouter(
    connect(mapStateToProps, {})(NewSideMenu)
);