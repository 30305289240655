import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import DataSourceTable from "../UserScreen/DataSourceTable";
import NewLogin from "../UserScreen/Login";
import FleetDashboard from "../UserScreen/FleetDashboard";
import AlertPage from '../UserScreen/AlertPage';
import MapPage from "../UserScreen/MapPage";
import ReportPage from "../UserScreen/ReportPage";
import AccountManagment from "../UserScreen/AccountMangement";
import ServiceManagement from "../UserScreen/ServiceManagement";
import SimReports from "../UserScreen/SimReports";
import TicketPage from "../UserScreen/TicketPage";
import TicketView from "../components/TicketView/index"
import FleetVesselVisualization from "../UserScreen/FleetDashboard/FleetVesselVisualization";
import EdgePortal from "../components/FleetSelection/EdgePortal"
import Header from '../components/Header';
import ForgotPassword from "../UserScreen/ForgotPassword/index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ChangePassword from '../UserScreen/ChangePassword'
import "./toast.css"
import updateUser from '../UserScreen/updateUser';
import viewUser from "../UserScreen/ViewUser";
import ReactGA from 'react-ga';
import SoftwareList from '../UserScreen/SoftwareList';
import { checkLoggedInUserAuthorizedToViewPage, getDataFromLocalStorage, getDecodeURI } from "../utils/util";
import { USER_OU } from "../constants/Constants";
import { updateSelectedOu, updateUserOu } from '../actions/Users/authenticate';
import SoftwareVersionDeviceDetails from '../components/SoftwareVersion-DeviceDetails';
import InventoryPage from '../UserScreen/InventoryPage';
import DailyDataUsage from '../UserScreen/DailyDataUsage';
import AuditLogs from '../components/AuditLogs';
import CreateAlert from '../UserScreen/CreateAlert';
import UserRegistration from '../UserScreen/Registration';
import ResetPassword from "../UserScreen/ResetPassword";
import QuotaManagement from '../UserScreen/QuotaManagement';
import FleetNewSummaryDashboard from '../UserScreen/FleetDashboard/FleetNewSummary'
import { useDispatch } from 'react-redux'
import { DOMAIN_CONFIG, DOMAIN_NAME } from '../actions/types';
import KnowledgeCenter from '../UserScreen/KnowledgeCenter';
import Starlink from '../UserScreen/Starlink';
import { KNOWLEDGE_CENTER } from '../utils/constants';
import { SERVICE_FUNCTION_TO_FEATURE } from '../config';
import NewSideMenu from '../components/NewSideMenu';
import HubDetails from '../UserScreen/HubDetails';
import ThreatDashboard from '../UserScreen/ThreatDashboard/ThreatDashboard';

const RootRouter = (props) => {
  const dispatch = useDispatch();
  const { location, authReducer, updateSelectedOu, updateUserOu } = props;
  const [previousPath, setPreviousPath] = useState<string>("");
  const [isViewSummary, setIsViewSummary] = useState(true);
  const isAuthorizedToBeta= checkLoggedInUserAuthorizedToViewPage(SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"], SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"]);
  const [isDashBoard, setIsDashboard] = useState(location.pathname.includes("/fleet-dashboard") && localStorage.getItem("isBetaUser") === "true")

  useEffect(() => {
    let hostname = document.location.host;
    if(hostname) {
      if(hostname.includes("localhost")) {
        hostname = "sport";
        dispatch({ type: DOMAIN_NAME, payload:  hostname})
      } else if(hostname) {
        hostname = hostname
        dispatch({ type: DOMAIN_NAME, payload:  hostname})
      }
      fetch(`https://k4storage.blob.core.windows.net/cdn/sportAssets/${hostname}/domainConfig.json`).then(res => res.text()).then((data) => {
        if (data) {
          dispatch({ type: DOMAIN_CONFIG, payload:  JSON.parse(data)})
        } else {
          dispatch({ type: DOMAIN_CONFIG, payload:  {LLC:"",name:"K4"}})
        }
      })
    }
  },[document.location.host])

  useEffect(() => {
    let url = window.location.pathname + window.location.search;
    if (url !== previousPath) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      setPreviousPath(url);
    }
    if(location.pathname.includes("/fleet-dashboard")) {
      setIsDashboard(true && localStorage.getItem("isBetaUser") === "true")
      // setIsDashboard(true);
    } else {
      setIsViewSummary(true)    
      setIsDashboard(false);
    }
  }, [location.pathname]);
  if ("/" === location.pathname || "/login" === location.pathname || "/forgot-password" === location.pathname || location.pathname.startsWith("/add-user") || location.pathname.startsWith("/reset-password")) {
    return <main>
      <Switch>
        {/* Non-Auth pages */}
        <Route exact path="/" component={NewLogin} />
        <Route exact path="/login" component={NewLogin} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/add-user/:userNamePar/:passwordPar" component={UserRegistration} />
        <Route path="/reset-password/:confirmCode" component={ResetPassword} />
      </Switch>
    </main>
  }

  const loggedInUser = getDataFromLocalStorage("loggedInUser");
  const accesssTokenObj = loggedInUser && JSON.parse(loggedInUser);
  
  if (!loggedInUser || "" === loggedInUser || accesssTokenObj?.data?.access_token === "" || !accesssTokenObj.data?.access_token) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  } else {
    let selectedOu = authReducer?.userOu;
    if (null == authReducer?.userOu) {
      const _userOu: any = getDataFromLocalStorage(USER_OU);
      if (_userOu) {
        let userOu = JSON.parse(_userOu);
        updateUserOu(userOu);
      }
      selectedOu = _userOu;
      if (location?.search && '' != location?.search.trim()) {
        let _q = getDecodeURI(location?.search);
        if (_q.ouName && _q.ouId) {
          selectedOu = { name: _q.ouName, id: _q.ouId };
        }
      }
      if (selectedOu) {
        if (null == authReducer?.selectedOu || authReducer?.selectedOu?.name !== selectedOu?.name) {
          // Feels unnesseray needs check
          // updateSelectedOu(selectedOu);
        }
      }
    }
  }
  const handleToggle = () => {
    console.log("TOGLING");
    setIsViewSummary(isViewSummary => !isViewSummary);
  }

  return (
    <main>
      <Header handleToggle={handleToggle} isViewSUm={isViewSummary} isDashBoard={isDashBoard}/>
      <ToastContainer />
      <NewSideMenu > 

      <Switch>
        {/* Pages */}
        <Route exact path="/fleet-dashboard/:tab" component={isViewSummary? FleetDashboard:FleetNewSummaryDashboard} />
        <Route exact path="/fleet-dashboard/newsummary/:tab" component={FleetNewSummaryDashboard} />
        <Route exact path="/fleet-dashboard/newsummary/sites/:tab" component={FleetNewSummaryDashboard} />
        <Route exact path="/fleet-dashboard/sites/:tab" component={FleetVesselVisualization} />
        <Route exact path="/fleet-dashboard/sites/alerts/create-alert" component={CreateAlert} />
        <Route exact path="/reports/:tab" component={SimReports} />
        <Route exact path="/reports/dataUsage/dailyUsage/:vesselName/:k4Id" component={DailyDataUsage} />
        <Route exact path="/fleet-dashboard/sites/topology/edge-portal" component={EdgePortal} />
        <Route exact path="/alerts/:tab" component={AlertPage} />
        <Route exact path="/reports" component={ReportPage} />
        <Route exact path="/vessel-map" component={MapPage} />
        <Route exact path="/account-management/:tab" component={AccountManagment} />
        <Route exact path="/service-management" component={ServiceManagement} />
        <Route exact path="/ticket/:status" component={TicketPage} />
        <Route exact path="/tickets" component={TicketPage} />
        <Route exact path="/data-sources" component={DataSourceTable} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/updateUser" component={updateUser} />
        <Route exact path="/viewUser" component={viewUser} />
        <Route exact path="/softwareList" component={SoftwareList} />
        <Route exact path="/softwareList/:softwareVersion/devices" component={SoftwareVersionDeviceDetails} />
        <Route exact path="/inventory" component={InventoryPage} />
        <Route exact path="/audit-logs" component={AuditLogs} />
        <Route exact path="/quota-management" component={QuotaManagement} />
        <Route path="/starlink/:tab" component={Starlink} />
        <Route path="/hub" component={HubDetails} />
        <Route path="/cyber" component={ThreatDashboard} />
        <Route exact path={"/"+KNOWLEDGE_CENTER} component={KnowledgeCenter} />
      </Switch>
      </NewSideMenu>
     
    </main>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(connect(mapStateToProps, { updateSelectedOu, updateUserOu })(RootRouter));