import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, TextField, Chip, IconButton, Typography, Button, Stack } from '@mui/material';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';

interface ServiceLineDetailsProps {
    row: any;  // or type the row properly if you have a specific type
}

const ServiceLineDetails: React.FC<ServiceLineDetailsProps> = ({ row }) => {
    const [isEditingServiceLine, setIsEditingServiceLine] = useState(false);
    const [isEditingHomeAddress, setIsEditingHomeAddress] = useState(false);
    const [serviceLineName, setServiceLineName] = useState('ABC');
    const [homeAddress, setHomeAddress] = useState('XYZ');
    const [emails, setEmails] = useState<any>([]);
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState('');

      useEffect(()=>{
        setServiceLineName(row?.serviceLineName)
        setEmails(row?.emailIds)
        // setHomeAddress(row?.address?.formattedAddress)
      },[row])

    const handleEditToggle = (type) => {
        if (type === 'serviceLine') setIsEditingServiceLine(!isEditingServiceLine);
        else setIsEditingHomeAddress(!isEditingHomeAddress);
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails((prev) => prev.filter((email) => email?.email !== emailToDelete.email));
    };

    const handleAddEmail = (e) => {
        if (e.key === 'Enter' && emailInput) {
            setEmails([...emails, { email: emailInput, name:'' }]);
            setEmailInput('');
        }
    };

    return (
        <div className="optIn-container">

            <Box display="flex" alignItems="center" mb={1}>
                
                <Typography fontWeight={"550"} component="div" className="starlink--base--font_1x">Account Number:</Typography>
                <Typography variant="body1" ml={1}> {row?.accountNumber}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
                <Typography  fontWeight={"550"} component="div" className="starlink--base--font_1x">Service Line Number: </Typography>
                <Typography variant="body1"  ml={1}>  {row?.serviceLineNumber}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
                <Typography  fontWeight={"550"} component="div" className="starlink--base--font_1x">Service Line Name: </Typography>
                {isEditingServiceLine ? (
                    <>
                        <TextField
                            size="small"
                            value={serviceLineName}
                            onChange={(e) => setServiceLineName(e.target.value)}
                            sx={{ mr: 1, ml: 1 }}
                        />
                        <IconButton onClick={() => handleEditToggle('serviceLine')} color="primary">
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditToggle('serviceLine')} color="secondary">
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Typography variant="body1" sx={{ ml: 1 }}> {serviceLineName}</Typography>
                        {/* <IconButton onClick={() => handleEditToggle('serviceLine')} sx={{ ml: 1 }}>
                            <EditIcon />
                        </IconButton> */}
                    </>
                )}
            </Box>


            <Box display="flex" alignItems="center" mb={1}>
                <Typography  fontWeight={"550"} component="div" className="starlink--base--font_1x">Home Address:   </Typography>
                {isEditingHomeAddress ? (
                    <>
                        <TextField
                            size="small"
                            value={homeAddress}
                            onChange={(e) => setHomeAddress(e.target.value)}
                            sx={{ mr: 1, ml: 1 }}
                        />
                        <IconButton onClick={() => handleEditToggle('homeAddress')} color="primary">
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditToggle('homeAddress')} color="secondary">
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Typography variant="body1" sx={{ ml: 1 }}> {row?.address?.formattedAddress}</Typography>
                        {/* <IconButton onClick={() => handleEditToggle('homeAddress')} sx={{ ml: 1 }}>
                            <EditIcon />
                        </IconButton> */}
                    </>
                )}
            </Box>

            {/* Email Ids */}
            <Typography  fontWeight={"550"} component="div" className="starlink--base--font_1x" mb={1}>Email Ids:</Typography>
            <Grid item xs={12}>
                <Box>
                    <Paper
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: '8px',
                            borderRadius: '10px',
                            border: '1px solid #c2d3eb',
                            boxShadow: 'none',
                        }}
                    >
                        {emails.map((email, index) => (
                            <Chip
                                key={index}
                                label={email?.email}
                                onDelete={() => handleDeleteEmail(email)}
                                sx={{ margin: '4px' }}
                            />
                        ))}
                        <TextField
                            size="small"
                            fullWidth
                            variant="standard"
                            error={!!error}
                            placeholder="Press Enter to add email"
                            helperText={error}
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleAddEmail}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    padding: '0 4px',
                                },
                            }}
                            sx={{
                                width: 'auto',
                                flexGrow: 1,
                                minWidth: '120px',
                                '& .MuiInputBase-root': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '8px 4px',
                                    flex: 1,
                                },
                            }}
                        />
                    </Paper>
                </Box>
                <Typography variant="caption" color="textSecondary" sx={{ marginTop: '4px', marginLeft: '8px' }}>
                    Quota Kontrol Notifications shall be sent to this email.
                </Typography>
            </Grid>
        </div>
    );
};

export default ServiceLineDetails;
